export const contactus = [
  {
    heading: "Interested in exploring our products & services?",
    title4: "Let’s talk Gifting",
    desc: "To get in touch with us, kindly use the following contact form.",
    title1: "Talk to us",
    title2: " Visit us",
    title3: "Follow us",
  },
];
