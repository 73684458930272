import React, { useState, useEffect } from 'react';
  import { DateCreated } from './DateShare';
import { truncateText, truncateTextNorm } from '../../../utils/truncateText';
import slugify from 'slugify';
import { useMediaQuery } from 'react-responsive';

export const useScreenSize = () => {
  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  return { isLargeScreen, isMediumScreen, isSmallScreen };
}

export const RelatedArticle = ({post}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClick  = () => {
    setRedirectTo(`/blogs/article/${slugify(post.title, { lower: true })}/${post._id}`);
  };
  const { isLargeScreen, isMediumScreen, isSmallScreen } = useScreenSize();

  return (
    <>     
          <div className="my-3 my-xl-4"> 
          <div
          className='related-article box-shadow-medium rounded-4 d-none d-md-block relative'
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        >     

        <div className="row d-flex flex-row justify-content-between">
        <div className='col-5 related-img'>
        <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText}  />
               </div>
            
            <div className="col-7 py-3 my-auto pe-4">
            <h5>{truncateText(post.title, 60)}</h5>
            <p className='  my-lg-3' style={{ color: "#404040" }}>{truncateTextNorm(post.mainParagraph, isLargeScreen, isMediumScreen, isSmallScreen)}</p>
            <div style={{ color: "#404040" }}>
            <DateCreated createdAt={post.createdAt}   />
            </div>
          </div>
        </div>
      </div>
      </div>
 </>
  )
}
 
export const RelatedArticleMob = ({post}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClickMob  = () => {
    setRedirectTo(`/blogs/article/${slugify(post.title, { lower: true })}/${post._id}`);
  };
    return (
      <>
<div onClick={handleClickMob} className='related-mobile-card'>  
  <div className='trending-card box-shadow-medium trending-img d-flex flex-col mx-2 mt-3 '>
    <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className='trending-card-img-display '/>
    <div className="trending-content p-3 p-sm-3 flex-grow-1">  
      <h5>{truncateText(post.title, 40)}</h5>
      <p className='text-dasGrayDark '>{truncateText(post.mainParagraph, 30)}</p>
      <p className='text-dasGrayDark'> <DateCreated createdAt={post.createdAt}/></p>
    </div>
  </div>
</div>


       

       </>
         )
}

 