import React from "react";
import { footer_reachus } from "../../../data/footer";
import "./location.css";
const Location = () => {
  const handleClick = () => {
    window.open("https://goo.gl/maps/xAKvaqnE6KU6N1ELA", "_blank");
  };

  return (
    <div>
      {footer_reachus.map((val) => (
        <>
          <div
            role="button"
            onClick={handleClick}
            className="location d-flex    flex-row"
          >
            <div role="button" className="location d-flex gap-3 flex-row">
              <h5 className=" align-self-start d-flex">{val.icon_location}</h5>
              <p className="regular   ">{val.address}</p>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};
export default Location;
