export const banner = [
  {
    left: "./Images/banner/Trustline-Corporate-Gifts.svg",
    alt1: "Corporate gifts for employees, clients, and partners.",
    right: "./Images/banner/Trustline-Business-Gift.svg",
    alt2: "We pick the ideal corporate gifts for your business needs.",
    text1: "Take the first step towards finding the",
    text2: "perfect",
    text3: "corporate gift or uniform. Download our catalog today.",
  },
];
