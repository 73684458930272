import React, { useEffect, useState } from "react";

import { thankyou, images } from "../../data/thankyou";
import "./thankyou.css";
import {
  Facebook,
  Instagram,
  LinkedIn,
  TikTok,
  Whatsapp,
} from "../../components/buttons/social-icons/SocialLinks";
import Footer from "../../components/footer/Footer";
import DefaultNav1 from "../../components/navbar/defaultnav/DefaultNav1";
import { ThankyouHelmet } from "../../PreclickTags/Preclick";
import GTM from "../../googletagmanager/GTM";
const Thankyou = () => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  function handleClick() {
    setRedirectTo("/");
  }
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCurrentImage(1);
    }, 800);
  }, []);

  return (
    <>
      <GTM />
      <ThankyouHelmet />
      <DefaultNav1 />
      <section
        className="thankyou pt-lg-5 pt-sm-3"
        style={{ background: "#fdfffc" }}
      >
        <div className="container section-padding   my-5">
          <div className="row d-flex justify-content-evenly">
            {thankyou.map((val) => (
              <>
                <div className="col-lg-5 col-md-11 col-12">
                  <img
                    src={images[currentImage]}
                    style={{ transition: "300ms ease-in-out" }}
                    alt={val.alt}
                  />
                </div>
                <div className="col-lg-4 col-md-10 col-12 my-auto">
                  <h1 className="manrope semibold">
                    {val.title1}
                    <text className=" playflair  italic">{val.title2} </text>
                  </h1>
                  <p className="medium manrope">{val.desc}</p>
                  <div className="d-flex p-0 flex row  ">
                    <ul class="list-unstyled d-flex social-icons">
                      <h4 role="button">
                        <Facebook />
                      </h4>
                      <li class="ms-2 " role="button">
                        <h4>
                          <LinkedIn />
                        </h4>
                      </li>
                      <li class="ms-2" role="button">
                        <h4>
                          <Instagram />
                        </h4>
                      </li>
                      <li class="ms-2" role="button">
                        <h4>
                          <Whatsapp />{" "}
                        </h4>
                      </li>
                      <li class="ms-2" role="button">
                        <h4>
                          <TikTok />{" "}
                        </h4>
                      </li>
                    </ul>
                  </div>
                  <button
                    className="back-to-home semibold p-2 "
                    onClick={handleClick}
                  >
                    Go Back Home
                  </button>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Thankyou;
