import React from "react";
import { IoChevronUpCircleOutline } from "react-icons/io5";
import { whatsnew_cards, whatsnew } from "../../../data/home";
import { FiArrowRightCircle } from "react-icons/fi";
import { Heading } from "../../../components/heading/Heading";
 import { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
const WhatsNew = ({ targetDivRef }) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo);
    }
  }, [redirectTo, navigate]);

  function handleClick() {
    setRedirectTo("/contact-us");
  }

  const [deviceWidth, setDeviceWidth] = useState(0);

  useEffect(() => {
    setDeviceWidth(window.innerWidth);
  }, []);
  return (
    <>
      <section className=" WhatsNew">
        <div className="   container " ref={targetDivRef}>
          <div className="whatsnew-image  hidemeformobile hidemeforstandardtablet">
            <div className="row  pt-lg-3 pt-2">
              {whatsnew.map((val) => (
                <>
                  <div className="bg-whats   d-flex justify-content-end">
                    <img src={val.background} alt={val.alt1} />
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className={deviceWidth < 768 ? "section-padding" : ""}>
            <div className="   Whats-new-section">
              <div className="row ">
                <div className="col-12 bold col-md-12">
                  <Heading title="What's New?" className="mymanrope semibold" />
                </div>
              </div>

              <div className="row  d-flex  z-0 position-relative justify-content-center mx-auto gap-0">
                {whatsnew_cards.map((val) => (
                  <>
                    <div class=" mx-auto  col-6 col-md-4 col-lg-3 pt-lg-4 pt-1 pt-md-3">
                      <div class="slide-up1 px-2  mx-auto ">
                        <div class="box1 mx-auto ">
                          <div className="product-image-whatsnew ">
                            <img src={val.product1_image} alt={val.alt1} />
                          </div>

                          <div class="overlay1 showmeforpc ">
                            <h5 className="bold mymanrope   align-self-center">
                              {val.product1_name}
                            </h5>
                            <div className="showmeonhover showmeforpc ">
                              <p className="medium showmeforpc">
                                {val.product1_desc}
                              </p>

                              <button
                                onClick={handleClick}
                                className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                              >
                                Lets talk!
                              </button>
                            </div>
                          </div>
                          <div className="  hidemeforpc ">
                            <div className="card-footer  overlay2">
                              <div className="d-flex flex-row   justify-content-between   product-title-mobile ">
                                <div className="       ">
                                  <h5 className="bold mymanrope      ">
                                    {val.product1_name}
                                  </h5>
                                </div>
                                <div className="     ">
                                  <i>
                                    <IoChevronUpCircleOutline />
                                  </i>
                                </div>
                              </div>
                              <div className="showmeonhover">
                                <p className="medium d-flex   justify-content-start ">
                                  Need more info?
                                </p>
                                <button
                                  onClick={handleClick}
                                  className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                >
                                  Lets talk!
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="   col-6 col-md-4 col-lg-3 pt-lg-4 pt-1 pt-md-3">
                      <div class="slide-up1 px-2  mx-auto ">
                        <div class="box1 mx-auto">
                          <div className="product-image-whatsnew ">
                            <img src={val.product2_image} alt={val.alt2} />
                          </div>

                          <div class="overlay1 showmeforpc ">
                            <h5 className="bold mymanrope   align-self-center">
                              {" "}
                              {val.product2_name}
                            </h5>
                            <div className="showmeonhover showmeforpc ">
                              <p className="medium showmeforpc">
                                {val.product2_desc}
                              </p>

                              <button
                                onClick={handleClick}
                                className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                              >
                                Lets talk!
                              </button>
                            </div>
                          </div>
                          <div className="  hidemeforpc ">
                            <div className="card-footer  overlay2">
                              <div className="d-flex flex-row   justify-content-between   product-title-mobile ">
                                <div className="       ">
                                  <h5 className="bold mymanrope      ">
                                    {val.product2_name}
                                  </h5>
                                </div>
                                <div className="">
                                  <i>
                                    <IoChevronUpCircleOutline />
                                  </i>
                                </div>
                              </div>
                              <div className="showmeonhover">
                                <p className="medium d-flex   justify-content-start ">
                                  Need more info?
                                </p>
                                <button
                                  onClick={handleClick}
                                  className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                >
                                  Lets talk!
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="   col-6 col-md-4 col-lg-3 pt-lg-4 pt-1 pt-md-3">
                      <div class="slide-up1 px-2  mx-auto ">
                        <div class="box1 mx-auto">
                          <div className="product-image-whatsnew ">
                            <img src={val.product3_image} alt={val.alt3} />
                          </div>

                          <div class="overlay1 showmeforpc ">
                            <h5 className="bold mymanrope   align-self-center">
                              {" "}
                              {val.product3_name}
                            </h5>
                            <div className="showmeonhover showmeforpc ">
                              <p className="medium showmeforpc">
                                {val.product3_desc}
                              </p>

                              <button
                                onClick={handleClick}
                                className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                              >
                                Lets talk!
                              </button>
                            </div>
                          </div>
                          <div className="  hidemeforpc ">
                            <div className="card-footer  overlay2">
                              <div className="d-flex flex-row   justify-content-between   product-title-mobile ">
                                <div className="       ">
                                  <h5 className="bold mymanrope      ">
                                    {val.product3_name}
                                  </h5>
                                </div>
                                <div className="     ">
                                  <i>
                                    <IoChevronUpCircleOutline />
                                  </i>
                                </div>
                              </div>
                              <div className="showmeonhover">
                                <p className="medium d-flex   justify-content-start ">
                                  Need more info?
                                </p>
                                <button
                                  onClick={handleClick}
                                  className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                >
                                  Lets talk!
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="   col-6 col-md-4 col-lg-3 pt-lg-4 pt-1 pt-md-3">
                      <div class="slide-up1 px-2  mx-auto ">
                        <div class="box1  mx-auto">
                          <div className="product-image-whatsnew ">
                            <img src={val.product4_image} alt={val.alt4} />
                          </div>

                          <div class="overlay1 showmeforpc ">
                            <h5 className="bold mymanrope   align-self-center">
                              {" "}
                              {val.product4_name}
                            </h5>
                            <div className="showmeonhover showmeforpc ">
                              <p className="medium showmeforpc">
                                {val.product4_desc}
                              </p>

                              <button
                                onClick={handleClick}
                                className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                              >
                                Lets talk!
                              </button>
                            </div>
                          </div>
                          <div className="  hidemeforpc ">
                            <div className="card-footer  overlay2">
                              <div className="d-flex flex-row   justify-content-between   product-title-mobile ">
                                <div className="       ">
                                  <h5 className="bold mymanrope      ">
                                    {val.product4_name}
                                  </h5>
                                </div>
                                <div className="     ">
                                  <i>
                                    <IoChevronUpCircleOutline />
                                  </i>
                                </div>
                              </div>
                              <div className="showmeonhover">
                                <p className="medium d-flex   justify-content-start ">
                                  Need more info?
                                </p>
                                <button
                                  onClick={handleClick}
                                  className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                >
                                  Lets talk!
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="   col-6 col-md-4 col-lg-3 pt-lg-4 pt-1 pt-md-3">
                      <div class="slide-up1 px-2  mx-auto ">
                        <div class="box1 ">
                          <div className="product-image-whatsnew ">
                            <img src={val.product5_image} alt={val.alt5} />
                          </div>

                          <div class="overlay1 showmeforpc ">
                            <h5 className="bold mymanrope   align-self-center">
                              {" "}
                              {val.product5_name}
                            </h5>
                            <div className="showmeonhover showmeforpc ">
                              <p className="medium showmeforpc">
                                {val.product5_desc}
                              </p>

                              <button
                                onClick={handleClick}
                                className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                              >
                                Lets talk!
                              </button>
                            </div>
                          </div>
                          <div className="  hidemeforpc ">
                            <div className="card-footer  overlay2">
                              <div className="d-flex flex-row   justify-content-between   product-title-mobile ">
                                <div className="       ">
                                  <h5 className="bold mymanrope      ">
                                    {val.product5_name}
                                  </h5>
                                </div>
                                <div className="     ">
                                  <i>
                                    <IoChevronUpCircleOutline />
                                  </i>
                                </div>
                              </div>
                              <div className="showmeonhover">
                                <p className="medium d-flex   justify-content-start ">
                                  Need more info?
                                </p>
                                <button
                                  onClick={handleClick}
                                  className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                >
                                  Lets talk!
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="      col-12 col-sm-12  h-50   col-lg-9 pt-lg-5">
                      {whatsnew.map((val) => (
                        <>
                          <div className="cant-decide-card ms-lg-2 col-lg-12  row  position-relative">
                            <div className="col-9 col-sm-6   d-flex flex-column ">
                              <div className="my-auto card-text ">
                                <h3 className="semibold   ">
                                  {val.card_title1}
                                </h3>
                                <h5 className="medium    ">
                                  {val.card_title2}
                                </h5>
                              </div>
                            </div>

                            <div
                              className=" position-absolute    justify-content-end
d-flex col-sm-6 align-middle chatbot-img "
                            >
                              <img src={val.ChatBot} alt={val.alt2} />
                            </div>
                            <div className="cta-button position-absolute bottom-0 mb-2  d-flex justify-content-end">
                              <button onClick={handleClick}>
                                <div className="material-2   ">
                                  <i class="  my-auto ">
                                    <span>
                                      {" "}
                                      <FiArrowRightCircle />
                                    </span>
                                  </i>
                                </div>
                              </button>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatsNew;
