export const privacy = [
  {
    Head: "PRIVACY POLICY",
    desc1:
      " At Trustline, we understand the importance of protecting the privacy of our customers. We are committed to ensuring that your personal information is protected in accordance with the laws of the United Arab Emirates. ",

    desc2:
      "This Privacy Policy explains how we collect, use, and protect your personal information. ",
    sub1: "  Collection of Personal Information: ",
    sub1_desc:
      " We may collect personal information such as your name, email address, and phone number when you download our catalogue or place an order with us.",
    sub2: "  	Use of Personal Information:",

    sub2_desc1:
      " We use your personal information to process and fulfill your orders. We may also use your personal information to send you promotional emails and other marketing materials.",
    sub2_desc2:
      "We will not share your personal information with any third parties, except as necessary to fulfill your order or as required by law.",

    sub3: " Protection of Personal Information:   ",
    sub3_desc1:
      "We take steps to protect your personal information from unauthorized access, use, or disclosure. We use encryption and other security measures to protect your personal information when it is transmitted over the internet. ",

    sub3_desc2:
      "We also have policies and procedures in place to ensure that your personal information is handled and stored securely. ",

    sub4: " Updating your personal information: ",
    sub4_desc:
      " You can update your personal information at any time. If you would like to update your personal information, please contact us.  ",
    sub5: " Changes to Privacy Policy: ",
    sub5_desc:
      "We may update this privacy policy from time to time. If we make any changes to this policy, we will post the updated policy on our website. ",

    sub6: "Contact Us:",
    sub6_desc:
      "If you have any questions or concerns regarding this privacy policy, please contact us.  ",
    li1: "Trust Line General Trading, LLC. ",
    li2: "Unit 404, Nastaran Tower, Jaddaf Waterfront, Al Jaddaf, Dubai, UAE",
    li3: "+9714 397 8705",
    li3_2: "+971 52 631 0235",
    li4: "hello@trustlinegifts.com",
  },
];
export const tandc = [
  {
    Head: "TERMS & CONDITIONS",
    desc1:
      "   These terms and conditions govern your use of the website and services of (Trustline, “we”, “our” or “us”). By accessing or using our website you agree to be bound by these terms. These Terms shall be governed by and construed in accordance with the laws of the United Arab Emirates.   ",

    sub1: " Ordering & Payment:  ",
    sub1_desc1:
      "	We will only consider your order as accepted once we have notified you of our acceptance in writing (for example, by email or text message). If we cannot accept your order, we will inform you through written communication or phone call, and we will not charge you for the product.  ",
    sub1_desc2:
      " All orders must be placed by contacting Trustline’s Sales team. Payment for the services must be made in advance, and our company reserves the right to cancel or postpone any order if payment is not received in a timely manner. We accept payment by credit card, debit card, and bank transfer. All prices are in UAE Dirhams and are inclusive of VAT. ",

    sub2: " Returns & Refunds",

    sub2_desc1:
      "Custom orders are non-refundable and non-returnable. We will provide you with a digital sample of the custom design before production begins. Once the sample is approved and production begins, the order cannot be canceled or refunded.",
    sub2_desc2:
      "If you are not satisfied with your order, please contact us within 7 days of receiving the goods. We do not accept returns or issue refunds for corporate gifts that have been customized or personalized. If a gift is damaged or defective, the company will replace the gift at no additional cost. ",
    sub3: "  Shipping & Delivery: ",
    sub3_desc:
      "Our company will arrange for the delivery of the corporate gifts to the specified address. We will make all reasonable efforts to deliver the gifts on the specified date. However, we are not responsible for any delays in delivery caused by factors beyond our control. ",

    sub4: " Privacy of your Personal Information: ",
    sub4_desc:
      "Trustline respects your privacy and is committed to protecting your personal information. We will not share, sell, or disclose your personal information to any third party without your consent. We will only use your personal information for the purpose of providing the services. We will also take reasonable steps to protect your personal information from unauthorized access or use. ",

    sub5: " Limitation of Liability",
    sub5_desc:
      "Our Company’s liability to you in relation to the services is strictly limited to the total amount paid by you for the services. ",

    sub6: " Services: ",
    sub6_desc:
      "Trustline provides a wide range of corporate gifting & uniform services, including but not limited to the selection, design, and delivery of corporate gifts and uniforms to customers and employees. ",
    li1: "Trust Line General Trading, LLC.",
    sub7: " Changes to Terms: ",
    sub7_desc:
      "Trustline reserves the right to make changes to these Terms at any time. Your continued use of the Services following any changes to these Terms will be deemed. ",
    sub8: "Intellectual Property:  ",
    sub8_desc:
      "Our Company’s intellectual property, including but not limited to its logos, trademarks, and copyrighted material, may not be used without our written consent. ",
    sub9: " Warranty: ",
    sub9_desc:
      "We guarantee that the product purchased from us will be of satisfactory quality and free from defects. However, this warranty does not cover defects caused by fair wear & tear, failure to follow care instructions, willful damage, storage, accident, negligence, or any alteration or repair made by you or a third party.",
    h5: "These Terms, together with our Privacy Policy and any other agreements referenced herein, constitute the entire agreement between you and our company regarding the use of the Services. ",
  },
];
