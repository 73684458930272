import React from "react";
import "./downloadbutton.css";
import { View } from "../../../data/icons";
import { GiOpenBook } from "react-icons/gi";
const DownloadButton = () => {
  const handleClick = () => {
    window.open(
      "https://trustlinegeneraltrading-my.sharepoint.com/:b:/g/personal/hello_trustlinegifts_com/EU7UpE-_Z4hAkXwcpdxRTx0BmF1S9RXP4IDBnpkzPaJLFA",
      "_blank"
    );
  };
  return (
    <>
      <div className="downloadbutton">
        <div className="d-flex justify-content-center">
          <button
            onClick={handleClick}
            className="download-button p-2 p-sm-3 text-center d-flex justify-content-center flex-row semibold"
          >
            <span className="  my-auto">View Catalog</span>
            <div className="  my-auto">
              {View.map((val) => (
                <i class="  ps-2   ">
                  <span className="  my-auto">
                    <GiOpenBook />
                  </span>
                </i>
              ))}
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default DownloadButton;
