import React from "react";
import { footer_reachus } from "../../../data/footer";
import "../location/location.css";
const Mail = () => {
  return (
    <div className="mail">
      {footer_reachus.map((val) => (
        <>
          <a
            href="mailto:hello@trustlinegifts.com"
            className="text-decoration-none"
          >
            <div
              role="button"
              className="location text-wrap d-flex gap-3 flex-row"
            >
              <h5 className=" align-self-start d-flex">{val.icon_mail}</h5>
              <p className="regular   ">{val.mail}</p>
            </div>
          </a>
        </>
      ))}
    </div>
  );
};

export default Mail;
