import React from "react";
import "./footer.css";
import logogreen from '../../Images/logo/green/Trustline-Corporate-Gifting-Logo-Green.svg'

import { footer_bg } from "../../data/footer";

const FooterLogo = () => {
  return (
    <>
      <div className="footer-logo">
        {footer_bg.map((val) => (
          <>
            <div role="button">
              <img className="px-4 py-2" src={logogreen} alt="Green Trustline company logo is a symbol of our commitment to excellence." />
            </div> 
          </>
        ))}
      </div>
    </>
  );
};

export default FooterLogo;
