import { RiPhoneLine } from "react-icons/ri";
import { HiOutlineLocationMarker, HiOutlineMail } from "react-icons/hi";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { BsWhatsapp, BsTelephone } from "react-icons/bs";
import { BiCalendarEvent } from "react-icons/bi";
export const footer_bg = [
  {
    background: "./Images/Footer/Footer-bg.svg",
    logo: "./Images/logo/green/Navbar_Green_Logo_Default.svg",
    text: "With us Quality has no Compromise!",
    title1: "Quick Links",
    title2: "Services",
    title3: "Reach Us",
  },
];
export const footer_links = [
  {
    id: 1,
    link: "About",
    linkto: "/about-us ",
  },
  {
    id: 2,
    link: "Contact",
    linkto: "/contact-us ",
  },
  {
    id: 3,
    link: "Blogs",
    linkto: "/blogs ",
  },
  {
    id: 4,
    link: "Terms & Conditions",
    linkto: " /terms-and-conditions",
  },
  {
    id: 5,
    link: "Privacy Policy",
    linkto: "/privacy-policy ",
  },
  {
    id: 6,
    link: "Sitemap",
    linkto: "/sitemap",
  },
];

export const footer_reachus = [
  {
    icon_location: <HiOutlineLocationMarker />,
    address:
      "Unit 404, Nastaran Tower, Jaddaf Waterfront, Al Jaddaf, Dubai, UAE",
    icon_phone: <RiPhoneLine />,
    icon_phone2: <HiDevicePhoneMobile />,
    contact: " +9714 397 8705",
    mob: "+971 52 631 0235",
    icon_mail: <HiOutlineMail />,
    mail: "hello@trustlinegifts.com",
  },
];
export const float_button = [
  {
    text1: "Call",
    text2: "WhatsApp",
    text3: "Book",
    icon: <BsTelephone />,
    icon2: <BsWhatsapp />,
    icon3: <BiCalendarEvent />,
  },
];
