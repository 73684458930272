import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export const ContactBreadcrumb = () => {
  return (
    <div>
      <Breadcrumb style={{ fontStyle: "manrope" }}>
        <BreadcrumbItem>
          <Link to="/" style={{ color: "  #666666" }}>
            Home
          </Link>
        </BreadcrumbItem>

        <BreadcrumbItem active style={{ color: " #084A44", fontWeight: "700" }}>
          Contact
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};

export const AboutBreadcrumb = () => {
  return (
    <div>
      <Breadcrumb style={{ fontStyle: "manrope" }}>
        <BreadcrumbItem>
          <Link to="/" style={{ color: "  #666666" }}>
            Home
          </Link>
        </BreadcrumbItem>

        <BreadcrumbItem active style={{ color: " #084A44", fontWeight: "700" }}>
          About
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};
