import React from "react";
import { about, our_values } from "../../data/about";
import "./aboutus.css";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { useState, useEffect } from "react";
import CatalogDownload from "../../components/banner/CatalogDownload";
import Footer from "../../components/footer/Footer";
import DefaultNav1 from "../../components/navbar/defaultnav/DefaultNav1";
import { AboutBreadcrumb } from "../../components/breadcrumb/Breadcrumbs";
import { AboutHelmet } from "../../PreclickTags/Preclick";
import GTM from "../../googletagmanager/GTM";

const AboutScreen = () => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  function handleClick() {
    setRedirectTo("/contact-us");
  }
  return (
    <>
      <GTM />
      <AboutHelmet />
      <DefaultNav1 />
      <section className="about mt-5  ">
        {about.map((val) => (
          <>
            <div className="  about-hero  p-lg-5  ">
              <div className="container section-padding">
                <div className="row">
                  <AboutBreadcrumb />
                </div>
                <div className="row ">
                  <div className="col-xl-6 col-md-12 col-12">
                    <h1 className="semibold manrope">{val.heading}</h1>
                    <h4 className="bold manrope ">
                      {val.t1}
                      <text className="italic playfleir pe-2">{val.t2}</text>
                      {val.t3}
                    </h4>
                    <p className="medium manrope pt-2 mb-2">{val.desc1}</p>
                    <p className="medium manrope  ">{val.desc2}</p>
                  </div>
                  <div className="col-xl-6 col-md-12 col-12 about-image">
                    <img src={val.cover} alt={val.alt} />
                  </div>
                </div>
              </div>
            </div>

            <div className="gradient-div">
              <div class="container d-flex h-30vh justify-content-center align-items-center">
                <div class="row">
                  <div class="text-center col-11 col-md-11 col-lg-12 mx-auto px-lg-5 p-lg-4 p-4 card-about ">
                    <h3 className="semibold manrope">{val.card_t1}</h3>
                    <h5 className="medium  manrope">{val.card_desc}</h5>
                    <button
                      className="Talk-to-us-about px-lg-3 p-2 mt-3 "
                      onClick={handleClick}
                    >
                      <div className="d-flex   align-items-center  ">
                        <span>
                          Talk to Us
                          <i>
                            <IoArrowForwardCircleOutline className="ms-2" />
                          </i>
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="container section-padding">
              <div className="row our-values">
                <h2 className="semibold manrope text-center ">Our Values</h2>
                <div className="row py-lg-5 py-sm-4 ">
                  {our_values.map((val) => (
                    <>
                      <div className="col-12 col-sm-6 col-lg-3  text-center">
                        <div className="our-values-icon mx-auto py-3">
                          <img src={val.icon} alt={val.alt} />
                        </div>

                        <h5 className="semibold manrope">{val.title}</h5>
                        <p className="medium">{val.desc}</p>
                      </div>{" "}
                    </>
                  ))}
                </div>
              </div>
            </div>
            <CatalogDownload />
          </>
        ))}
      </section>
      <Footer />
    </>
  );
};

export default AboutScreen;
