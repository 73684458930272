import React, { useRef } from "react";

import Hero from "./hero/Hero";
import WhatsNew from "./whats-new/WhatsNew";
import BestCorporate from "./best-coorporate/BestCorporate";

import { QrCode } from "./qr-section/QrCode";
import { OrderingProcess } from "./ordering-process/OrderingProcess";
import HomeNav from "../../components/navbar/homenav/HomeNav";
import CatalogDownload from "../../components/banner/CatalogDownload";
import HowCanHelp from "./services/HowCanHelp";
import Footer from "../../components/footer/Footer";
import { HomeHelmet } from "../../PreclickTags/Preclick";
import GTM from "../../googletagmanager/GTM";

const HomeScreen = () => {
  const targetDivRef = useRef(null);
  return (
    <>
      <GTM />
      <HomeHelmet />
      <HomeNav />
      <Hero targetDivRef={targetDivRef} />
      <WhatsNew targetDivRef={targetDivRef} />
      <HowCanHelp />
      <BestCorporate />
      <QrCode />
      <OrderingProcess />
      <CatalogDownload />
      <Footer />
    </>
  );
};

export default HomeScreen;
