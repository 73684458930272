import React from "react";
import { ordering_process } from "../../../data/home";

export const OrderingProcess = () => {
  return (
    <>
      <section className="ordering-process manrope section-padding">
        <div className="container">
          <div className="row">
            <h2 className="manrope semibold">Our Ordering Process</h2>
          </div>

          <div className="row d-flex  content-section justify-content-between mx-auto">
            {ordering_process.map((val) => (
              <>
                <div className="col-12 col-sm-6 col-lg-2 d-flex justify-content-start  py-2 flex-column content-body ">
                  <div className="d-flex flex-row image-section align-items-center   ">
                    <div className="ordering-image ">
                      <img src={val.icon} alt={val.alt} />
                    </div>
                    <div className="dashed-line ms-3"></div>
                  </div>
                  <div className="row py-2">
                    {" "}
                    <h5 className="semibold">{val.title}</h5>
                  </div>

                  <p className="medium">{val.text}</p>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
