import React from "react";
import "./contactus.css";

import { Container, Row, Col } from "reactstrap";
import {
  Facebook,
  Instagram,
  LinkedIn,
  TikTok,
  Whatsapp,
} from "../../components/buttons/social-icons/SocialLinks";

import { contactus } from "../../data/contact";
import ContactForm from "./ContactForm";
import Footer from "../../components/footer/Footer";
import DefaultNav1 from "../../components/navbar/defaultnav/DefaultNav1";
import { ContactBreadcrumb } from "../../components/breadcrumb/Breadcrumbs";
import { ContactHelmet } from "../../PreclickTags/Preclick";
import GTM from "../../googletagmanager/GTM";
import { footer_reachus } from "../../data/footer";

const ContactUs = () => {
  const handleClick = () => {
    window.open("https://goo.gl/maps/xAKvaqnE6KU6N1ELA", "_blank");
  };
  return (
    <>
      <GTM />
      <ContactHelmet />
      <DefaultNav1 />
      <section className="contactus section-padding  mt-5    ">
        <Container>
          <Row>
            <Col xs="12" className="ps-xl-5 ps-md-3 ps-lg-4 ps-2">
              <ContactBreadcrumb />
            </Col>
          </Row>
          {contactus.map((val) => (
            <>
              <Row className="justify-content-evenly pb-5 mb-lg-5 ">
                <Col
                  xs="12"
                  md="12"
                  lg="6"
                  className="order2 manrope talk-to-us my-auto"
                >
                  <h1 className="mymanrope semibold showmeforpc">
                    Interested in exploring our products & services?
                  </h1>
                  <div className="contact-box  row p-lg-4 col-lg-9 p-4 p-md-4 p-sm-3   ">
                    <div className="col-lg-12 col-md-8 col-sm-8 col-12    ">
                      <h3 className="bold manrope ">{val.title1}</h3>
                      {footer_reachus.map((item) => (
                        <>
                          <a
                            href="tel:+9714-397-8705"
                            className="text-decoration-none"
                          >
                            <div
                              role="button"
                              className="location d-flex gap-3  flex-row py-2 py-lg-4 py-sm-3"
                            >
                              <h5 className=" align-self-start d-flex  semibold my-auto">
                                {item.icon_phone}
                              </h5>
                              <h6 className="my-auto  semibold">
                                {item.contact}
                              </h6>
                            </div>
                          </a>

                          <a
                            href="tel:+971-52-631-0235"
                            className="text-decoration-none py-2 py-lg-4 py-sm-3"
                          >
                            <div
                              role="button"
                              className="location d-flex semibold gap-3 flex-row"
                            >
                              <h5 className=" align-self-start semibold d-flex my-auto">
                                {item.icon_phone2}
                              </h5>
                              <h6 className="my-auto  semibold ">
                              +971 52 631 0235
                              </h6>
                            </div>
                          </a>

                          <a
                            href="mailto:hello@trustlinegifts.com"
                            role="button"
                            className="location text-decoration-none semibold text-wrap d-flex gap-3 flex-row py-2 py-lg-4 py-sm-3"
                          >
                            <h5 className=" align-self-start d-flex my-auto semibold ">
                              {item.icon_mail}
                            </h5>
                            <h6 className="my-auto semibold  ">{item.mail}</h6>
                          </a>
                          <h3 className="bold manrope ">{val.title2}</h3>
                          <a
                            role="button"
                            onClick={handleClick}
                            className="location semibold d-flex gap-3 flex-row pb-2 text-decoration-none py-lg-2 py-sm-1"
                          >
                            <h5 className=" align-self-start d-flex semibold">
                              {item.icon_location}
                            </h5>
                            <h6 className="my-auto  semibold ">
                              {item.address}
                            </h6>
                          </a>
                        </>
                      ))}
                    </div>
                    <div className=" col-lg-12 col-md-3 col-sm-3 col-12 follow-us   ">
                      <h3 className="bold manrope">{val.title3}</h3>
                      <div className="d-flex p-0 flex row">
                        <ul class="list-unstyled d-flex social-icons">
                          <h3 role="button">
                            <Facebook />
                          </h3>

                          <li class="ms-2 " role="button">
                            <h3>
                              <LinkedIn />
                            </h3>
                          </li>
                          <li class="ms-2" role="button">
                            <h3>
                              <Instagram />
                            </h3>
                          </li>
                          <li class="ms-2" role="button">
                            <h3>
                              <Whatsapp />
                            </h3>
                          </li>
                          <li class="ms-2" role="button">
                            <h3>
                              <TikTok />
                            </h3>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="12" lg="5" className="my-auto">
                  <h2 className="mymanrope semibold hidemeforpc">
                    Interested in exploring our products & services?
                  </h2>
                  <div className="my-auto contact-box2 order1    p-lg-4 p-md-3  ">
                    <ContactForm />
                  </div>
                </Col>
              </Row>
            </>
          ))}
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default ContactUs;
