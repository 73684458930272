import React from "react";
import { loading } from "../../data/thankyou";
import "./wait.css";

const Wait = () => {
  return (
    <>
      <div className="  py-4  my-auto">
        {loading.map((val) => (
          <>
            <div className="WaitContainer">
              <h4 className="semibold manrope pb-3 section-padding">
                {val.title}
              </h4>
              <div className="load-image WaitImage mx-auto ">
                <img src={val.image} alt={val.alt} />
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default Wait;
