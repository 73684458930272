export const about = [
  {
    heading: "About the Company",
    cover: "./Images/about/Trustline-Dubai-Gift-Supplier.svg",
    alt: "Trustline, the go-to for corporate gifts & uniforms options in Dubai, offers a one-stop-solution for premium giveaways.",
    t1: "Experience the magic of ",
    t2: "corporate gifts & uniforms",
    t3: "with our exclusive range of gifting options in Dubai & UAE",
    desc1:
      " At Trustline, we are passionate about creating corporate gifts and uniforms that help businesses connect with their clients, employees, and partners. With over 17 years of experience in the industry, our team of experts are dedicated to delivering top-quality products and exceptional customer service. From customized corporate gifts to stylish uniforms, we offer a wide range of options to suit your specific needs. We believe in the power of branding and the impact it can have on a company's image and success. That's why we offer extensive customization options and work closely with our clients to create the perfect gifts and uniforms to represent their brand.",
    desc2:
      " But we're not just about the products. We also prioritize sustainability and corporate social responsibility in all that we do. We source our materials responsibly and have implemented eco-friendly practices in our manufacturing process. Our Vision is To be the U.A.E’s leading corporate gifts & uniforms provider with a one stop solution for all needs.",

    card_t1: "Don’t know where to start?",
    card_desc:
      "Let our gifting experts take the stress out of corporate gifting.",
  },
];
export const our_values = [
  {
    icon: "./Images/about/Trustline-Excellence-Values.svg ",
    alt: "The quality of our products and services is extremely important to us, and we strive for excellence.",
    title: "Excellence",
    desc: "From the quality of our goods to the quality of our services, we aim for perfection in everything we do.",
  },
  {
    icon: "./Images/about/Trustline-Innovation-Gifting-Solutions.svg  ",
    alt: " We offer creative gifting solutions while exceeding client expectations.",
    title: "Innovation",
    desc: "We are constantly thinking of new ways to surprise and amaze our clients with creative gifting & uniform solutions while exceeding their expectations.",
  },
  {
    icon: "./Images/about/Trustline-Flexibility-Gifting.svg  ",
    alt: "Collaborate with us to design personalized gifting experiences that align with your specific objectives and goals.",
    title: "Flexibility",
    desc: "We think that each client is unique, therefore we collaborate with you to design personalized giving experiences that match your specific objectives and goals.",
  },
  {
    icon: "./Images/about/Trustline-Dedication-Client-Commitment.svg  ",
    alt: "Committed to ensuring client satisfaction and building long-term relationships through dedicated service.",
    title: "Dedication",
    desc: "We are committed to long-term client relationships and going above and beyond to ensure their satisfaction.",
  },
];
