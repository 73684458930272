import React from "react";
import { BestCoorporate } from "../../../data/home";
const BestCorporate = () => {
  return (
    <>
      <section className=" section-padding">
        <div className=" best-coorporate">
          <div className="container  ">
            <div className="row  ">
              <h2 className="first-head manrope semibold">
                What makes us the best
              </h2>
            </div>
            <div className="row">
              <div className="col-12 playflair-head">
                <h2 className="fst-italic bold">
                  corporate gifts and uniform supplier
                </h2>
              </div>
            </div>
            <div className="row  ">
              <h2 className="first-head semibold">in Dubai?</h2>
            </div>
            <div className="best-corporate-cards">
              <div className="row d-flex  justify-content-around mx-auto">
                {BestCoorporate.map((val) => (
                  <>
                    <div className="col-12 col-sm-6 col-lg-2 card-item ">
                      <div className="card-body">
                        <img className="image py-1" src={val.icon} alt={val.alt} />
                        <h5 className="semibold py-2">{val.title}</h5>
                        <p className="medium">{val.desc}</p>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BestCorporate;
