import React from "react";

import { privacy } from "../../data/termsofuse";
import DefaultNav1 from "../../components/navbar/defaultnav/DefaultNav1";
import Footer from "../../components/footer/Footer";
import { PrivacyHelmet } from "../../PreclickTags/Preclick";
import GTM from "../../googletagmanager/GTM";
const PrivacyPolicy = () => {
  const handleClick = () => {
    window.open("https://goo.gl/maps/S16bpXyuC3JnSk6x5", "_blank");
  };
  return (
    <>
      <GTM />
      <PrivacyHelmet />
      <DefaultNav1 />
      <section
        className="terms manrope  my-5 background-baby"
        style={{ color: "#1a1a1a", background: "#fdfffc" }}
      >
        <div className="container section-padding  background-baby ">
          {privacy.map((val) => (
            <>
              <h1 className="bold text-center">{val.Head}</h1>
              <p className="medium justify-content-start ">{val.desc1}</p>
              <p className="medium justify-content-start  ">{val.desc2}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub1}</h5>
              <p className="medium justify-content-start ">{val.sub1_desc}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub2}</h5>
              <p className="medium justify-content-start ">{val.sub2_desc1}</p>
              <p className="medium justify-content-start  ">{val.sub2_desc2}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub3}</h5>
              <p className="medium justify-content-start ">{val.sub3_desc1}</p>
              <p className="medium justify-content-start ">{val.sub3_desc2}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub4}</h5>
              <p className="medium justify-content-start ">{val.sub4_desc}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub5}</h5>
              <p className="medium justify-content-start ">{val.sub5_desc}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub6}</h5>
              <p className="medium justify-content-start ">{val.sub6_desc}</p>
              <ul className="list-unstyled  w-50 gap-1">
                <li>
                  <b>{val.li1}</b>{" "}
                </li>
                <li
                  role="button"
                  onClick={handleClick}
                  className=" py-1 py-md-2  "
                  style={{ color: "#084a44", textDecoration: "none" }}
                >
                  {val.li2}
                </li>
                <a
                  href="tel:+9714-397-8705"
                  style={{ color: "#084a44", textDecoration: "none" }}
                >
                  <li>{val.li3}</li>
                </a>
                <a
                  href="tel:+971-52-631-0235"
                  style={{ color: "#084a44", textDecoration: "none" }}
                >
                  <li className="py-1 py-md-2">{val.li3_2}</li>
                </a>
                <a
                  href="mailto:hello@trustlinegifts.com"
                  style={{ color: "#084a44", textDecoration: "none" }}
                >
                  <li>{val.li4}</li>
                </a>
              </ul>
            </>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
