import React from "react";
import { Facebook, Instagram, LinkedIn, TikTok, Whatsapp } from "./SocialLinks";
const Socialicons = () => {
  return (
    <>
      <div className="d-flex p-0 flex row">
        <ul class="list-unstyled d-flex social-icons">
          <h3 role="button">
            <Facebook />
          </h3>
          <li class="ms-2 " role="button">
            <h3>
              <LinkedIn />
            </h3>
          </li>
          <li class="ms-2" role="button">
            <h3>
              <Instagram />
            </h3>
          </li>
          <li class="ms-2" role="button">
            <h3>
              <Whatsapp />
            </h3>
          </li>
          <li class="ms-2" role="button">
            <h3>
              <TikTok />
            </h3>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Socialicons;
