import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AboutScreen from "./about-us/AboutScreen";
import ContactUs from "./contact-us/ContactUs";

import NotFound from "./empty-states/NotFound";
import HomeScreen from "./home/HomeScreen";
import Thankyou from "./thankyou/Thankyou";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/offcanvas";
import Sitemap from "./sitemap/Sitemap";
import PrivacyPolicy from "./terms-of-use/PrivacyPolicy";
import TermsandConditions from "./terms-of-use/Terms&Conditions";
import CookiePolicy from "./terms-of-use/CookiePolicy";
import CookieConsent from "../cookie/CookieConsent";
import SitemapXML from "../sitemap-xml/SitemapXML";
import BlogsHome from '../pages/blogs/BlogHome'
import Article from "./article/Article";
const Pages = () => {
  return (
    <  >
 <CookieConsent/>
  <Router>
    <Routes> 
   
          <Route exact path="/" element={<HomeScreen />} />
          <Route path="/about-us" element={<AboutScreen />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/thank-you" element={<Thankyou />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/Cookie-policy" element={<CookiePolicy />} />
          <Route path="/sitemap.xml" element={<SitemapXML />} />
            <Route path="/blogs" element={<BlogsHome />} /> 

          <Route
            path="/terms-and-conditions"
            element={<TermsandConditions />}
          />
          <Route path="*" element={<NotFound />} />
    <Route path="/blogs/article/:title/:id" element={<Article/>} />   
    </Routes> 
  </Router>
    </ >
  )
}

export default Pages
