import React from "react";
import { hero } from "../../../data/home";
import { useNavigate } from "react-router-dom";
import "../home.css";

const Hero = ({ targetDivRef }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    const targetDiv = targetDivRef.current;
    const targetTop = targetDiv.offsetTop;
    const targetHeight = targetDiv.offsetHeight;
    const windowHeight = window.innerHeight;
    const offset = Math.floor((targetHeight - windowHeight) * 0.001) - 30;
    window.scrollTo({
      top: targetTop + offset,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="hero-section container-fluid  ">
        {hero.map((val) => (
          <>
            <div className="row background-images-hero    ">
              <div className="col-md-1"> </div>
              <div className="col-md-2 d-flex justify-content-start   ">
                <div className=" cup-image  d-flex   align-items-center">
                  <img
                    className="background-images-hero  "
                    src={val.mug}
                    alt={val.alt1}
                  />
                </div>
              </div>
              <div className="col-md-2"> </div>
              <div className="col-md-2">
                <div className="watch-image d-flex justify-content-end">
                  <img
                    className="background-images-hero  "
                    src={val.watch}
                    alt={val.alt2}
                  />
                </div>
              </div>
              <div className="col-md-2"> </div>
              <div className="col-md-2">
                <div className="cap-image d-flex  justify-content-start">
                  <img
                    className="background-images-hero  "
                    src={val.cap}
                    alt={val.alt3}
                  />
                </div>
              </div>
            </div>

            <div className="row background-images-hero   ">
              <div className="col-md-4  d-flex justify-content-start ">
                <div className="packaging     ">
                  <img
                    className="background-images-hero  "
                    src={val.packaging}
                    alt={val.alt5}
                  />
                </div>
              </div>
              <div className="col-md-5"></div>
              <div className="col-md-3    d-flex justify-content-end  background-images-hero ">
                <div className="tshirt      ">
                  <img
                    className="background-images-hero  "
                    src={val.tshirt}
                    alt={val.alt4}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7 hero-center-text position-absolute top-50 start-50 translate-middle">
              <div class="px-4  my-auto  text-center">
                <h1 className="  semibold manrope ">
                  We are your one-stop solution for
                  <text className="  bold playflair greentext fst-italic px-2">
                    corporate gifts
                  </text>
                  <text className="    fst-italic playflair">&</text>
                  <text className=" px-2 bold playflair greentext fst-italic">
                    uniforms
                  </text>
                </h1>
                <div class="   mx-auto">
                  <p class="  mb-4">
                    Win the hearts of your coworkers with the best corporate
                    gift items and uniforms in Dubai.
                  </p>
                  <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    <button
                      type="button"
                      class="btn primary-btn  btn-lg px-4 gap-3"
                      onClick={() => navigate("/contact-us")}
                    >
                      <h5 className="semibold manrope my-auto">Talk to us</h5>
                    </button>
                    <button
                      type="button"
                      class="btn secondary-btn  btn-lg px-4"
                      onClick={handleClick}
                    >
                      <h5 className="semibold manrope my-auto"> What's New</h5>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default Hero;
