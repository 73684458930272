import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

const GTM = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: "GTM-NVTCVCC",
    });
  }, []);

  return null;
};

export default GTM;
