import React from "react";
import "./banner.css";
import { banner } from "../../data/banner";
import DownloadButton from "../buttons/download-button/DownloadButton";
const CatalogDownload = () => {
  return (
    <>
      <section className="banner">
        <div className="container-fluid text-center position-relative">
          <div className="row py-4  ">
            <div className="d-flex   justify-content-center m-0">
              {banner.map((val) => (
                <>
                  <div className="col-xxl-3 col-xl-2 col-md-2 left-image  showmeforpc">
                    <>
                      <img src={val.left} alt={val.alt2} />
                    </>
                  </div>
                  <div className="col-12 col-sm-11 col-lg-8 col-xl-8 col-md-8 d-flex banner-content flex-column mx-auto my-auto  ">
                    <div className="  row     ">
                      <h3 className="semibold manrope">
                        Take the first step towards finding the
                        <text className="  px-2 bold playflair  fst-italic">
                          perfect
                        </text>
                        corporate gift or uniform. Download our catalog today.
                      </h3>
                    </div>
                    <div class="  mt-lg-4 mt-3   mx-auto">
                      <DownloadButton />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-2 col-md-2 right-image showmeforpc">
                    <>
                      <img src={val.right} alt={val.alt1} />
                    </>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CatalogDownload;
