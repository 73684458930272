import { useMediaQuery } from 'react-responsive';
export const truncateText = (text, limit) => {
  if (text.length > limit) {
      return text.substring(0, limit) + '...';
  }
  return text;
};

export const useScreenSize = () => {
  const isLargeScreen = useMediaQuery({ minWidth: 1200 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  return { isLargeScreen, isMediumScreen, isSmallScreen };
}
// Modify truncateText to accept screen size parameters
export const truncateTextNorm = (text, isLargeScreen, isMediumScreen, isSmallScreen) => {
  let limit;

  if (isLargeScreen) {
    limit = 180;   
  } else if (isMediumScreen) {
    limit = 40;   
  } else if (isSmallScreen) {
    limit = 60;   
  }

  if (text.length > limit) {
    return text.substring(0, limit) + '...';
  }
  return text;
};

export const getTitleCharLimit = (isLargeScreen, isMediumScreen, isSmallScreen) => {
  if (isLargeScreen) {
      return 25; 
  } else if (isMediumScreen) {
      return 20;
  } else if (isSmallScreen) {
      return 18; 
  }
  return 0;  
};

 
export const getParaCharLimit = (isLargeScreen, isMediumScreen, isSmallScreen) => {
  if (isLargeScreen) {
      return 50; 
  } else if (isMediumScreen) {
      return 40;
  } else if (isSmallScreen) {
      return 30; 
  }
  return 0;  
};
   