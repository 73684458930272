import React from "react";
import "./navcta.css";
import { RiPhoneLine } from "react-icons/ri";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { GiOpenBook } from "react-icons/gi";
export const NavCta = () => {
  return <div>NavCta</div>;
};
export const Download = () => {
  const handledownloadClick = () => {
    window.open(
      "https://trustlinegeneraltrading-my.sharepoint.com/:b:/g/personal/hello_trustlinegifts_com/EU7UpE-_Z4hAkXwcpdxRTx0BmF1S9RXP4IDBnpkzPaJLFA",
      "_blank"
    );
  };
  return (
    <div>
      <button
        className=" p-2 px-4  download-button-nav   d-flex flex-row semibold"
        onClick={handledownloadClick}
      >
        <p className="my-auto "> View Catalog</p>
        <div className=" ps-2 my-auto">
          <i class="my-auto     ">
            <GiOpenBook />
          </i>
        </div>
      </button>
    </div>
  );
};
export const Call = () => {
  return (
    <div>
      <a href="tel:+9714-397-8705" className="text-decoration-none">
        <button className="callbutton semibold d-flex my-auto flex-row gap-1">
          <h6 className="my-auto">
            <RiPhoneLine />
          </h6>
          <p className="my-auto">+9714 397 8705</p>
        </button>
      </a>
    </div>
  );
};
export const Mob = () => {
  return (
    <div>
      <a href="tel:+971-52-631-0235" className="text-decoration-none">
        <button className="callbutton semibold d-flex my-auto flex-row gap-1">
          <h6 className="my-auto">
            <HiDevicePhoneMobile />
          </h6>
          <p className="my-auto">+971 52 631 0235</p>
        </button>
      </a>
    </div>
  );
};
