import React from "react";
import Footer from "../../components/footer/Footer";
import DefaultNav1 from "../../components/navbar/defaultnav/DefaultNav1";
import { SitemapHelmet } from "../../PreclickTags/Preclick";
import GTM from "../../googletagmanager/GTM";
import "./sitemap.css";

function Sitemap() {
  const pages = [
    { title: "Home", link: "/" },
    { title: "About", link: "/about-us" },
    { title: "Contact", link: "/contact-us" },
    { title: "Privacy Policy", link: "/privacy-policy" },
    { title: "Cookie Policy", link: "/cookie-policy" },
    { title: "Terms & conditions", link: "/terms-and-conditions" },
    { title: "Blogs", link: "/blogs" },
    { title: "Blogs", link: "/blogs" },

  ];

  const pageList = pages.map((page, index) => {
    return (
      <li className="sitemap-item mx-auto text-start" key={index}>
        <a className="sitemap-link" href={page.link}>
          {page.title}
        </a>
      </li>
    );
  });

  return (
    <>
      <GTM />
      <SitemapHelmet />
      <DefaultNav1 />
      <section
        className="background-baby position-relative sitemap"
        style={{ background: "#fdfffc" }}
      >
        <div>
          <div className="sitemap-container container section-padding pb-5 ">
            <h2 className="manrope text-center bold py-5">Sitemap</h2>
            <ul className="sitemap-list semibold mx-auto">
              {pageList.slice(0, -1)}
            </ul>
            <div className="d-none">{pageList.slice(-1)}</div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Sitemap;
