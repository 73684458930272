import React from "react";

import { tandc } from "../../data/termsofuse";
import DefaultNav1 from "../../components/navbar/defaultnav/DefaultNav1";
import Footer from "../../components/footer/Footer";
import { TandCHelmet } from "../../PreclickTags/Preclick";
import GTM from "../../googletagmanager/GTM";
const TermsandConditions = () => {
  return (
    <>
      <GTM />
      <TandCHelmet />
      <DefaultNav1 />
      <section
        className="terms manrope background-baby  my-5"
        style={{ color: "#1a1a1a", background: "#fdfffc" }}
      >
        <div className="container section-padding   ">
          {tandc.map((val) => (
            <>
              <h1 className="bold text-center">{val.Head}</h1>
              <p className="medium justify-content-start pt-3">{val.desc1}</p>

              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub1}</h5>
              <p className="medium justify-content-start ">{val.sub1_desc1}</p>
              <p className="medium justify-content-start pt-2 ">
                {val.sub1_desc2}
              </p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub2}</h5>
              <p className="medium justify-content-start ">{val.sub2_desc1}</p>
              <p className="medium justify-content-start pt-2 ">
                {val.sub2_desc2}
              </p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub3}</h5>
              <p className="medium justify-content-start ">{val.sub3_desc}</p>

              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub4}</h5>
              <p className="medium justify-content-start ">{val.sub4_desc}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub5}</h5>
              <p className="medium justify-content-start ">{val.sub5_desc}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub6}</h5>
              <p className="medium justify-content-start ">{val.sub6_desc}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub7}</h5>
              <p className="medium justify-content-start ">{val.sub7_desc}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub8}</h5>
              <p className="medium justify-content-start ">{val.sub8_desc}</p>
              <h5 className="bold pt-lg-4 pt-sm-3 pt-2">{val.sub9}</h5>
              <p className="medium justify-content-start ">{val.sub9_desc}</p>
              <p className="medium justify-content-start pt-2 ">{val.h5}</p>
            </>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TermsandConditions;
