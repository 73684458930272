import React from "react";
import "./footer.css";
import FooterLogo from "./FooterLogo";
import { float_button, footer_bg, footer_links } from "../../data/footer";
import Location from "../buttons/location/Location";
import { Contact, Mob } from "../buttons/call/Contact";
import Mail from "../buttons/mail/Mail";
import Socialicons from "../buttons/social-icons/Socialicons";
import ServicesLinks from "./ServicesLinks";

const Footer = () => {
  const [redirectTo, setRedirectTo] = React.useState(null);
  React.useEffect(() => {
    if (redirectTo) {
      if (redirectTo.startsWith("#")) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        window.location.href = redirectTo;
      }
    }
  }, [redirectTo]);
  const handleClickConsultaion = () => {
    const url = "/contact-us";
    if (window.location.pathname === url) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setRedirectTo(url);
    }
  };
  const handleClickWhatsapp = () => {
    window.open("https://wa.me/message/IYDJ3WNMV74BF1", "_blank");
  };
  function handleCall() {
    window.location.href = "tel:+9714-397-8705";
  }
  return (
    <>
      <section className="footer container-fluid mb-0  manrope section-padding    ">
        <div className="container my-auto">
          {footer_bg.map((val) => (
            <>
              <footer className="pt-5 ">
                <div className="row d-flex justify-content-around">
                  <div className="col-12 col-md-12   col-lg-4">
                    <div className="row d-flex flex-column">
                      <div
                        role="button"
                        id="button5"
                        className="  footer-logo  "
                      >
                        <FooterLogo />
                      </div>
                      <div>
                        <p className="semibold  pt-3">{val.text}</p>
                        <Socialicons />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-lg-2   ">
                    <h5 className="medium   py-2">{val.title1}</h5>
                    {footer_links.map((val) => (
                      <>
                        <div class=" list-unstyled flex-column d-flex justify-content-start">
                          <a class="  p-0  " href={val.linkto}>
                            <p className="regular  "> {val.link}</p>
                          </a>
                        </div>
                      </>
                    ))}
                  </div>

                  <div className="col-12    col-sm-6 col-lg-2 mb-3 ">
                    <h5 className="medium   py-2">{val.title2} </h5>
                    <ServicesLinks />
                  </div>
                  <div class="col-12 col-sm-12 reachus col-lg-3 mb-3">
                    <h5 className="medium manrope py-2">{val.title3} </h5>
                    <ul class="list-unstyled d-flex flex-column">
                      <>
                        <li class="list-unstyled  ">
                          <Location />{" "}
                        </li>
                        <li class="list-unstyled  ">
                          <Contact />
                        </li>
                        <li class="list-unstyled  ">
                          <Mob />
                        </li>
                        <li class="list-unstyled  ">
                          <Mail />
                        </li>
                      </>
                    </ul>
                  </div>
                </div>
                <div class="d-flex flex-column flex-lg-row justify-content-between align-self-baseline  pt-2 mb-3 mb-sm-0 mt-lg-5 pt-xl-5 regular manrope ">
                <p className="powered"> <a   href="https://trustlinedigital.com/"> Powered by Trustline Digital</a></p>
                  <p>
                    Copyright &copy; 2005 Trust Line General Trading,
                    LLC. All Rights Reserved
                  </p>
                </div>
              </footer>
            </>
          ))}
        </div>
      </section>
      <div className="showmeformobile" fluid>
        {float_button.map((val) => (
          <div className="row" style={{ color: "#0e857b" }}>
            <div className="col-12 ">
              <div className="floatingbutton ">
                <div
                  className="col-10 mx-auto semibold  d-flex gap-0  btn-group"
                  role="group"
                >
                  <button
                    type="button"
                    class="btn   phone-btn "
                    style={{ color: "#0e857b" }}
                    onClick={handleCall}
                  >
                    <i className=" bold">{val.icon}</i>
                    <p className="pt-1  semibold">{val.text1}</p>
                  </button>
                  <button
                    type="button"
                    class="btn   whatsapp-btn  bold"
                    onClick={handleClickWhatsapp}
                    style={{ color: "#0e857b" }}
                  >
                    {val.icon2}
                    <p className="pt-1  semibold">{val.text2}</p>
                  </button>
                  <button
                    type="button"
                    class="btn book-btn  bold "
                    onClick={handleClickConsultaion}
                    style={{ color: "#0e857b" }}
                  >
                    {val.icon3}
                    <p className="pt-1  semibold">{val.text3}</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Footer;
