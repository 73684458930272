import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./defaultnav1.css";
import { nav } from "../../../data/nav";
import { Call, Download, Mob } from "./NavCta";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/offcanvas";
import logogreen from '../../../Images/logo/green/Trustline-Corporate-Gifting-Logo-Green.svg'
const DefaultNav1 = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setActiveLink("home");
        break;
      case "/about-us":
        setActiveLink("about");
        break;
      case "/contact-us":
        setActiveLink("contact");
        break;
        case "/blogs":
        setActiveLink("blogs");
        break;
      default:
        setActiveLink("");
    }
  }, [location]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <>
      <div className="default">
        <nav className="navbar navbar-expand-lg  default-nav   fixed-top">
          <div className="container">
            {nav.map((val) => (
              <a className="navbar-brand" href="/">
                <img src={logogreen} alt="Green Trustline company logo is a symbol of our commitment to excellence." />
              </a>
            ))}
            <div className=" showmefortablet-lg  ">
              <div className="d-flex flex-row  ">
                <div className=" my-auto ">
                  <Call />
                </div>

                <div className=" ms-3 ">
                  <Download />
                </div>
              </div>
            </div>
            <button
              class="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="toggler-icon top-bar"></span>
              <span class="toggler-icon middle-bar"></span>
              <span class="toggler-icon bottom-bar"></span>
            </button>
            <div class="collapse navbar-collapse  " id="navbarSupportedContent">
              <div className="col-lg-5 navbar-links-row    ">
                <a
                  href="/"
                  onClick={() => handleLinkClick("home")}
                  className="nav-item"
                >
                  <p
                    className={`semibold manrope my-auto p-1 ms-3 ${
                      activeLink === "home" ? "active" : ""
                    }`}
                  >
                    Home
                  </p>
                </a>
                <a
                  href="/about-us"
                  onClick={() => handleLinkClick("about")}
                  className="nav-item"
                >
                  <p
                    className={`semibold manrope my-auto ms-3 p-1 ${
                      activeLink === "about" ? "active" : ""
                    }`}
                  >
                    About Us
                  </p>
                </a>
                <a
                  href="/contact-us"
                  onClick={() => handleLinkClick("contact")}
                  className="nav-item"
                >
                  <p
                    className={`semibold manrope my-auto p-1 ms-3 ${
                      activeLink === "contact" ? "active" : ""
                    }`}
                  >
                    Contact
                  </p>
                </a>
                <a
                  href="/blogs"
                  onClick={() => handleLinkClick("blogs")}
                  className="nav-item"
                >
                  <p
                    className={`semibold manrope my-auto p-1 ms-3 ${
                      activeLink === "blogs" ? "active" : ""
                    }`}
                  >
                    Blogs
                  </p>
                </a>
              </div>

              <div className="col-lg-1 col-xl-2 col-xxl-3"></div>
              <div className="hidemefortablet-lg ps-lg-5  col-xl-5 col-xxl-5   col-lg-7 ">
                <div className="d-flex call-download-mobile-default  mx-auto  gap-md-0 ">
                  <div className="my-auto pt-4 pt-md-0 ms-3 nav-item ">
                    <Call />
                  </div>
                  <div className="hidemeforpc">
                    <div className="my-auto   pt-4 pt-md-0 ms-3 nav-item ">
                      <Mob />
                    </div>
                  </div>
                  <div className="ms-3  nav-item ">
                    <Download />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default DefaultNav1;
