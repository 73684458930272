export const cookie = [
  {
    Head: "COOKIE POLICY",
    desc1:
      "Our company “Trustline” uses cookies on website to improve your browsing experience and to understand how you use our website. This Cookie Policy explains what cookies are, how we use them, and how you can control them.",
    sub1: " What are Cookies?",
    sub1_desc:
      "Cookies are small text files that are stored on your device when you visit a website. They are used to remember your preferences and to understand how you use the website. Cookies can be either session cookies, which are deleted when you close your browser, or persistent cookies, which remain on your device until they expire or you delete them.",
    sub2: " How we use Cookies",
    sub_semi:
      " We use cookies on our website for a variety of purposes, including:",
    sub2_desc1:
      "We use cookies to remember your preferences, such as your language and time zone, so that you don't have to enter them every time you visit our website.",
    sub2_desc2:
      "We use cookies to understand how you use our website, including which pages you visit and which products you view. This helps us to improve our website and to provide you with more relevant content and offers.",
    sub2_desc3:
      "We use cookies to understand how you use our website, so that we can improve it and make it more user-friendly.",
    sub3: "Third-Party Cookies  ",
    sub3_desc:
      "Our website may also use third-party cookies, which are placed by third-party service providers. These cookies may be used to track your browsing activity on our website and to show you relevant content and offers",

    sub4: "Controlling Cookies",
    sub4_desc:
      "You can control how cookies are used on our website by adjusting your browser settings. Most browsers allow you to block or delete cookies, but please note that this may affect how our website works and may prevent you from using some of its features.",
    sub5: "Changes to our Cookie Policy",
    sub5_desc:
      "We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on our website.",
    sub6: "Contact Us",
    sub6_desc:
      "If you have any questions about our Cookie Policy, please contact us at ",
    call: "+9714 397 8705",
    mob: "+971 52 631 0235",
    mail: "hello@trustlinegifts.com",
    h5: "By using our website, you acknowledge that you have read and understood this Cookie Policy and agree to our use of cookies in accordance with this policy.",
  },
];
export const cookie_consent = [
  {
    h3: "Hi there,",
    p1: "We use cookies to offer you a better browsing experience and to analyze site traffic. You may close this to continue with only essential cookies. See our",
    link: " Cookie Policy ",
    p3: "for more details.",
   
    alt: "We use cookies to improve your browsing experience and analyze site traffic.",
  },
];
