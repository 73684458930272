import React from "react";

import "swiper/css/free-mode";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  services_heading,
  services_personalised,
  services_curated,
  services_corporate,
  services_promotional,
} from "../../../data/home";
import { IoChevronUpCircleOutline } from "react-icons/io5";
import { Heading } from "../../../components/heading/Heading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
const HowCanHelp = () => {
 {/**  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  function handleClick() {
    setRedirectTo("/contact-us");
  }
*/}

  const [redirectTo, setRedirectTo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo);
    }
  }, [redirectTo, navigate]);

  function handleClick() {
    setRedirectTo("/contact-us");
  }
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [window.location.hash]);
  return (
    <>
      <section className="how-can-we-help section-padding">
        <div className="container">
          <div className="row">
            <Heading
              title=" How we can help you?"
              className="manrope semibold title"
            />
          </div>
          <div className="row d-flex flex-column " id="personalised-gift">
            {services_heading.map((val) => (
              <>
                <div className="col-12 col-lg-12 services-cards d-flex flex-column justify-content-start">
                  <div className="row d-flex flex-column py-3">
                    <h3 className="bold manrope ">{val.title1}</h3>
                    <h2 className="semibold playflair   fst-italic">
                      {val.grad1}
                    </h2>
                    <h3 className="bold manrope">{val.title2}</h3>
                    <h5 className="medium desc manrope">{val.desc1}</h5>
                  </div>
                  <div className="row product-card-section   ">
                    <Swiper
                      spaceBetween={10}
                      grabCursor={true}
                      modules={[Pagination]}
                      className="mySwiper"
                      breakpoints={{
                        0: {
                          slidesPerView: 1.5,
                        },
                        360: {
                          slidesPerView: 2.2,
                        },
                        480: {
                          slidesPerView: 2.8,
                          centeredSlides: false,
                        },
                        576: {
                          slidesPerView: 2.2,
                          centeredSlides: false,
                        },
                        768: {
                          slidesPerView: 3,
                          centeredSlides: false,
                        },
                        992: {
                          slidesPerView: 4,
                          centeredSlides: false,
                        },
                        1920: {
                          slidesPerView: 4,
                          centeredSlides: false,
                        },
                      }}
                    >
                      {services_personalised.map((val) => (
                        <SwiperSlide>
                          <div class="slide-up1 px-2  mx-auto ">
                            <div class="box1 ">
                              <div className="product-image-whatsnew ">
                                <img src={val.image} alt={val.alt} />
                              </div>
                              <div class="overlay1 showmeforpc ">
                                <h6 className="bold manrope   align-self-center">
                                  {val.product_name}
                                </h6>
                                <div className="showmeonhover showmeforpc ">
                                  <p className="medium showmeforpc">
                                    {val.desc}
                                  </p>

                                  <button
                                    onClick={handleClick}
                                    className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                  >
                                    Lets talk!
                                  </button>
                                </div>
                              </div>
                              <div className="  hidemeforpc ">
                                <div className="card-footer  overlay2">
                                  <div className="d-flex flex-row   justify-content-between   product-title-mobile ">
                                    <div className="  text-start     ">
                                      <h6 className="bold manrope      ">
                                        {val.product_name}
                                      </h6>
                                    </div>
                                    <div className="     ">
                                      <i>
                                        <IoChevronUpCircleOutline />
                                      </i>
                                    </div>
                                  </div>
                                  <div className="showmeonhover">
                                    <p className="medium d-flex   justify-content-start ">
                                      Need more info?
                                    </p>
                                    <button
                                      onClick={handleClick}
                                      className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                    >
                                      Lets talk!
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div class="swiper-pagination  padding-swiper"></div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="row d-flex flex-column " id="curated-gift-sets">
            {services_heading.map((val) => (
              <>
                <div className="col-12 col-lg-12 services-cards d-flex flex-column justify-content-start">
                  <div className="row d-flex flex-column py-5">
                    <h3 className="bold manrope ">{val.title3}</h3>
                    <h2 className="semibold playflair   fst-italic">
                      {val.grad2}
                    </h2>
                    <h3 className="bold manrope">{val.title4}</h3>
                    <h5 className="medium desc manrope">{val.desc2}</h5>
                  </div>
                  <div className="row product-card-section   ">
                    <Swiper
                      spaceBetween={10}
                      grabCursor={true}
                      modules={[Pagination]}
                      className="mySwiper"
                      breakpoints={{
                        0: {
                          slidesPerView: 1.5,
                        },
                        360: {
                          slidesPerView: 2.2,
                        },
                        480: {
                          slidesPerView: 2.8,
                          centeredSlides: false,
                        },
                        576: {
                          slidesPerView: 2.2,
                          centeredSlides: false,
                        },
                        768: {
                          slidesPerView: 3,
                          centeredSlides: false,
                        },
                        992: {
                          slidesPerView: 4,
                          centeredSlides: false,
                        },
                        1920: {
                          slidesPerView: 4,
                          centeredSlides: false,
                        },
                      }}
                    >
                      {" "}
                      {services_curated.map((val) => (
                        <SwiperSlide>
                          {" "}
                          <div class="slide-up1 px-2  mx-auto ">
                            <div class="box1 ">
                              <div className="product-image-whatsnew ">
                                <img src={val.image} alt={val.alt} />
                              </div>

                              <div class="overlay1 showmeforpc ">
                                <h6 className="bold manrope   align-self-center">
                                  {" "}
                                  {val.product_name}
                                </h6>
                                <div className="showmeonhover showmeforpc ">
                                  <p className="medium showmeforpc">
                                    {val.desc}
                                  </p>

                                  <button
                                    onClick={handleClick}
                                    className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                  >
                                    Lets talk!
                                  </button>
                                </div>
                              </div>
                              <div className="  hidemeforpc ">
                                <div className="card-footer overlay2">
                                  <div className="d-flex flex-row  justify-content-between  product-title-mobile ">
                                    <div className="  d-flex justify-content-start    ">
                                      <h6 className="bold manrope   text-start  ">
                                        {val.product_name}
                                      </h6>
                                    </div>
                                    <div className="  d-flex justify-content-end">
                                      <i>
                                        <IoChevronUpCircleOutline />
                                      </i>
                                    </div>
                                  </div>
                                  <div className="showmeonhover">
                                    <p className="medium d-flex   justify-content-start ">
                                      Need more info?
                                    </p>
                                    <button
                                      onClick={handleClick}
                                      className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                    >
                                      Lets talk!
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="row d-flex flex-column " id="corporate-event-gift">
            {services_heading.map((val) => (
              <>
                <div className="col-12 col-lg-12 services-cards d-flex flex-column justify-content-start">
                  <div className="row d-flex flex-column py-5">
                    <h3 className="bold manrope ">{val.title5}</h3>
                    <h2 className="semibold playflair   fst-italic">
                      {val.grad3}
                    </h2>
                    <h3 className="bold manrope">{val.title6}</h3>
                    <h5 className="medium desc manrope">{val.desc3}</h5>
                  </div>
                  <div className="row product-card-section   ">
                    <Swiper
                      spaceBetween={10}
                      grabCursor={true}
                      modules={[Pagination]}
                      className="mySwiper"
                      breakpoints={{
                        0: {
                          slidesPerView: 1.5,
                        },
                        360: {
                          slidesPerView: 2.2,
                        },
                        480: {
                          slidesPerView: 2.8,
                          centeredSlides: false,
                        },
                        576: {
                          slidesPerView: 2.2,
                          centeredSlides: false,
                        },
                        768: {
                          slidesPerView: 3,
                          centeredSlides: false,
                        },
                        992: {
                          slidesPerView: 4,
                          centeredSlides: false,
                        },
                        1920: {
                          slidesPerView: 4,
                          centeredSlides: false,
                        },
                      }}
                    >
                      {" "}
                      {services_corporate.map((val) => (
                        <SwiperSlide>
                          {" "}
                          <div class="slide-up1 px-2  mx-auto ">
                            <div class="box1 ">
                              <div className="product-image-whatsnew ">
                                <img src={val.image} alt={val.alt} />
                              </div>

                              <div class="overlay1 showmeforpc ">
                                <h6 className="bold manrope   align-self-center">
                                  {" "}
                                  {val.product_name}
                                </h6>
                                <div className="showmeonhover showmeforpc ">
                                  <p className="medium showmeforpc">
                                    {val.desc}
                                  </p>

                                  <button
                                    onClick={handleClick}
                                    className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                  >
                                    Lets talk!
                                  </button>
                                </div>
                              </div>
                              <div className="  hidemeforpc ">
                                <div className="card-footer overlay2">
                                  <div className="d-flex flex-row  justify-content-between  product-title-mobile ">
                                    <div className="  d-flex justify-content-start    ">
                                      <h6 className="bold manrope  text-start   ">
                                        {val.product_name}
                                      </h6>
                                    </div>
                                    <div className="  d-flex justify-content-end">
                                      <i>
                                        <IoChevronUpCircleOutline />
                                      </i>
                                    </div>
                                  </div>
                                  <div className="showmeonhover">
                                    <p className="medium d-flex   justify-content-start ">
                                      Need more info?
                                    </p>
                                    <button
                                      onClick={handleClick}
                                      className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                    >
                                      Lets talk!
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="row d-flex flex-column " id="promotional-gifts">
            {services_heading.map((val) => (
              <>
                <div className="col-12 col-lg-12 services-cards d-flex flex-column justify-content-start">
                  <div className="row d-flex flex-column py-5">
                    <h3 className="bold manrope ">{val.title7}</h3>
                    <h2 className="semibold playflair   fst-italic">
                      {val.grad4}
                    </h2>
                    <h3 className="bold manrope">{val.title8}</h3>
                    <h5 className="medium desc manrope">{val.desc4}</h5>
                  </div>
                  <div className="row product-card-section   ">
                    <Swiper
                      spaceBetween={10}
                      grabCursor={true}
                      modules={[Pagination]}
                      className="mySwiper"
                      breakpoints={{
                        0: {
                          slidesPerView: 1.5,
                        },
                        360: {
                          slidesPerView: 2.2,
                        },
                        480: {
                          slidesPerView: 2.8,
                          centeredSlides: false,
                        },
                        576: {
                          slidesPerView: 2.2,
                          centeredSlides: false,
                        },
                        768: {
                          slidesPerView: 3,
                          centeredSlides: false,
                        },
                        992: {
                          slidesPerView: 4,
                          centeredSlides: false,
                        },
                        1920: {
                          slidesPerView: 4,
                          centeredSlides: false,
                        },
                      }}
                    >
                      {" "}
                      {services_promotional.map((val) => (
                        <SwiperSlide>
                          {" "}
                          <div class="slide-up1 px-2  mx-auto ">
                            <div class="box1 ">
                              <div className="product-image-whatsnew ">
                                <img src={val.image} alt={val.alt} />
                              </div>

                              <div class="overlay1 showmeforpc ">
                                <h6 className="bold manrope   align-self-center">
                                  {" "}
                                  {val.product_name}
                                </h6>
                                <div className="showmeonhover showmeforpc ">
                                  <p className="medium showmeforpc">
                                    {val.desc}
                                  </p>

                                  <button
                                    onClick={handleClick}
                                    className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                  >
                                    Lets talk!
                                  </button>
                                </div>
                              </div>
                              <div className="  hidemeforpc ">
                                <div className="card-footer overlay2">
                                  <div className="d-flex flex-row  justify-content-between  product-title-mobile ">
                                    <div className="  d-flex justify-content-start    ">
                                      <h6 className="bold manrope text-start    ">
                                        {val.product_name}
                                      </h6>
                                    </div>
                                    <div className="  d-flex justify-content-end">
                                      <i>
                                        <IoChevronUpCircleOutline />
                                      </i>
                                    </div>
                                  </div>
                                  <div className="showmeonhover">
                                    <p className="medium d-flex   justify-content-start ">
                                      Need more info?
                                    </p>
                                    <button
                                      onClick={handleClick}
                                      className="btn lets-talk-btn d-grid col-10 mx-auto d-md-block "
                                    >
                                      Lets talk!
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </>
            ))}
          </div>

          {/**test */}
        </div>
      </section>
    </>
  );
};

export default HowCanHelp;
