import React, { useState, useEffect } from "react";
import axios from "axios";

const SitemapXML = () => {
  const [sitemap, setSitemap] = useState("");

  useEffect(() => {
    axios
      .get("https://trustlinegifts.com/sitemap.xml")
      .then((response) => {
        setSitemap(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return <div>{sitemap && <pre>{sitemap}</pre>}</div>;
};

export default SitemapXML;
