import React from 'react';
 import ShareButton from './Share';

const DateShare = ({ createdAt, articleUrl, articleTitle }) => {
  return (
    <div className="col-12 ">
    <div className='d-flex flex-row  justify-content-between my-2'>
   <DateCreated  createdAt={createdAt}/>
   <ShareButton articleUrl={articleUrl} articleTitle={articleTitle} />
    </div>
    </div>
  )
}

export default DateShare

export const DateCreated = ({ createdAt }) => {
  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const parts = formattedDate.split(' ');
    const day = parts[1].replace(',', '');
    const month = parts[0];
    const year = parts[2];
    return `${day}. ${month}. ${year}`;
  };

  const displayDate = formatDate(new Date(createdAt));
  return (
    <h6>{displayDate}</h6>
  )
}

 