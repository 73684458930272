import React from "react";

import { cookie } from "../../data/cookie";
import DefaultNav1 from "../../components/navbar/defaultnav/DefaultNav1";
import Footer from "../../components/footer/Footer";
import { CookieHelmet } from "../../PreclickTags/Preclick";
import GTM from "../../googletagmanager/GTM";
const CookiePolicy = () => {
  return (
    <>
      <GTM />
      <CookieHelmet />
      <DefaultNav1 />
      <section
        className="terms manrope background-baby  my-5"
        style={{ color: "#1a1a1a", background: "#fdfffc" }}
      >
        <div className="container section-padding  ">
          {cookie.map((val) => (
            <>
              <h1 className="bold text-center">{val.Head}</h1>
              <p className="medium justify-content-start pt-3">{val.desc1}</p>
              <h5 className="bold   pt-sm-3 pt-2">{val.sub1}</h5>
              <p className="medium">{val.sub1_desc}</p>
              <h5 className="bold   pt-sm-3 pt-2">{val.sub2}</h5>
              <h6 className="semibold">{val.sub_semi}</h6>
              <ul>
                <li>{val.sub2_desc1}</li>
                <li>{val.sub2_desc2}</li>
                <li>{val.sub2_desc3}</li>
              </ul>
              <h5 className="bold   pt-sm-3 pt-2">{val.sub3}</h5>
              <p className="medium">{val.sub3_desc}</p>
              <h5 className="bold   pt-sm-3 pt-2">{val.sub4}</h5>
              <p className="medium">{val.sub4_desc}</p>
              <h5 className="bold  pt-sm-3 pt-2">{val.sub5}</h5>
              <p className="medium">{val.sub5_desc}</p>
              <h5 className="bold   pt-sm-3 pt-2">{val.sub6}</h5>
              <p className="medium">
                {val.sub6_desc}{" "}
                <a
                  className="px-1"
                  href="tel:+9714-397-8705"
                  style={{ color: "#084a44", textDecoration: "none" }}
                >
                  {val.call}
                </a>
                ,
                <a
                  className="px-1"
                  href="tel:+971-52-631-0235"
                  style={{ color: "#084a44", textDecoration: "none" }}
                >
                  {val.mob}
                </a>{" "}
                or
                <a
                  className="px-1"
                  href="mailto:hello@trustlinegifts.com"
                  style={{ color: "#084a44", textDecoration: "none" }}
                >
                  {val.mail}
                </a>
              </p>
              <p className="semibold ">{val.h5}</p>
            </>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CookiePolicy;
