import {
  IoChevronDownCircleOutline,
  IoChevronUpCircleOutline,
} from "react-icons/io5";
export const hero = [
  {
    mug: "./Images/Home/hero/Trustline-Custom-Mug-Printing.png",
    alt1: "Custom mug with the company logo, showcasing the good quality mug printing options we offer",
    watch: "./Images/Home/hero/Trustline-Custom-Smart-Watch.png ",
    alt2: "A personalized smart watch for men and women, displaying one of the best smart watches.",
    cap: "./Images/Home/hero/Trustline-Custom-Cap-Printing.png",
    alt3: "Custom cap with our company logo. Multiple cap printing options are available.",
    tshirt: "./Images/Home/hero/Trustline-Custom-Hoodie.png",
    alt4: "White hoodie with our company logo, a great corporate gifting option.",
    packaging: "./Images/Home/hero/Trustline-Custom-Packaging-Bag.png",
    alt5: "Custom packaging bag featuring the company logo, highlighting our high-quality, customized packaging options.",
  },
];
export const whatsnew = [
  {
    test: "./Images/Home/whatsnew/Trustline-Custom-Power-Bank-Options.png",
    background: "./Images/Home/whatsnew/trustline-tshirt.png",
    alt1: "Custom t-shirt with company logo, various t-shirt printing options available.",
    card_title1: "Cant decide what to gift?",
    card_title2: " Speak to our specialists today.",
    ChatBot: "./Images/Home/whatsnew/Trustline-Chatbot-Illustration.svg",
    alt2: "Illustration of a chatbot, showing the use of automation for streamlined customer service and enhanced online performance.",
  },
];
export const whatsnew_cards = [
  {
    product1_name: "Mug ",
    product1_image:
      "./Images/Home/whatsnew/Trustline-Custom-Mug-with-Company-Logo.png",
    alt1: "Custom coffee mug with company’s logo; various mug printing options are available.",
    product1_desc:
      "Sip in style with our custom coffee mug! Personalize it with your company’s logo. Ideal gift for your team and clients.",
    product2_name: " T-shirt",
    product2_image:
      "./Images/Home/whatsnew/Trustline-Custom-T-Shirt-Printing.png",
    alt2: "Custom t-shirt featuring company logo, showcasing our expertise in high-quality t-shirt printing. ",
    product2_desc:
      "High-quality custom T-shirt with your company’s logo. Perfect for team building and promotions. Order now for a professional look!",
    product3_name: " Pen",
    product3_image: "./Images/Home/whatsnew/Trustline-Custom-Pen.png",
    alt3: "Custom pen with company logo, demonstrating our high-quality, custom pen options.",
    product3_desc:
      "Can't decide what to get? Here’s an ideal corporate gift for you. Get personalized pens with your company's logo.",
    product4_name: " Bottle",
    product4_image:
      "./Images/Home/whatsnew/Trustline-Custom-Bottle-Printing.png",
    alt4: "Custom water bottle with company logo, emphasizing our bottle printing expertise.",
    product4_desc:
      " Custom bottle with your company’s logo. Perfect for corporate gifting and events. High-quality and durable.",
    product5_name: "Power Bank ",
    product5_image:
      "./Images/Home/whatsnew/Trustline-Custom-Power-Bank-Options.png",
    alt5: "Custom power bank with company logo, showcasing one of the best power bank options we have.",
    product5_desc:
      "A lightweight power bank or portable charger that you can carry anywhere. Get a personalized power bank with your brand's logo on it.",
  },
];
export const services_heading = [
  {
    title1: "Add a",
    grad1: "personalized",
    title2: "touch to your gift",
    desc1:
      "Collaborate with us to create meaningful, personalized gifts that demonstrate how much you value your clients and employees. Develop your own custom gift set, with numerous items available at your fingertips.",

    title3: "Get",
    grad2: "curated gift sets",
    title4: "for your clients & employees",
    desc2:
      "Our curated gift sets are perfect for all occasions. We also customize gift packaging to better fit your requirements.",

    title5: "Impress with ease at any",
    grad3: "corporate event",
    title6: "with our thoughtful gifts",
    desc3:
      "Attending a corporate event? Leave a lasting first impression with our Corporate Event Gifts Collection.",

    title7: " Boost brand awareness with",
    grad4: "promotional gifts",
    title8: "& giveaways",
    desc4:
      "We help you build brand loyalty and improve brand recognition through our promotional gifts and giveaways.",
  },
];
export const product_arrow = [
  {
    icon1: <IoChevronDownCircleOutline />,
    icon2: <IoChevronUpCircleOutline />,
  },
];
export const services_personalised = [
  {
    image: "./Images/Home/Product-images/Trustline-Personalized-Cap.png",
    alt: "  Personalized cap with the company logo printed on it. Unlimited cap printing options.",
    product_name: "Cap",
    desc: "High-quality cap with the option of custom logo printing. Show off your brand in style with our personalized caps.",
  },
  {
    image: "./Images/Home/Product-images/Trustline-Personalized-Mousepad.png",
    alt: "Custom wireless charging mousepad with company logo, an ideal corporate gifting option.",
    product_name: "Charging Mousepad",

    desc: "Get your hands on our custom mouse pad charger. Experience the convenience of wireless charging while working. Personalize it with your company’s logo.",
  },
  {
    image: "./Images/Home/Product-images/Trustline-Best-Travel-Coffee-Mug.png",
    alt: " Custom travel mug, ideal for on-the-go coffee and as a corporate gift.",
    product_name: "Travel Mug",
    desc: "Custom Travel Mug for on-the-go branding. personalize it with your company's logo. Durable and leak-proof design.",
  },
  {
    image: "./Images/Home/Product-images/Trustline-Personalized-Keychain.png",
    alt: "Custom keychain with company logo, ideal for corporate gifting.",
    product_name: "Key Chain",
    desc: "Want to get a personalized keychain with your company’s logo? We've got you! Our keychains are made of high-quality material. Get yours today!",
  },
];
export const services_curated = [
  {
    image: "./Images/Home/Product-images/Trustline-Welcome-Gift-Set.png",
    alt: "Employee welcome gift set, including a notebook, pen, card holder, and more.",
    product_name: "Welcome Gift Set",
    desc: "Send a warm welcome to your new employees or business partners with our thoughtfully crafted gift pack. Customization available.",
  },
  {
    image: "./Images/Home/Product-images/Trustline-Holiday-Gift-Set.png",
    alt: "Holiday Gift Set, perfect for clients and employees. ",
    product_name: "Holiday Gift Set",
    desc: "Want the ideal holiday gift set for your coworkers, employees, or business partners? Check out our curated holiday gift set.",
  },
  {
    image: "./Images/Home/Product-images/Trustline-Tech-Gift-Set.png",
    alt: "Tech Gift Set with unlimited customization options for each product. ",
    product_name: "Tech Gift Set",
    desc: "Place an order for our high-quality tech products and take advantage of the limitless personalization choices we provide.",
  },
  {
    image:
      "./Images/Home/Product-images/Trustline-Luxury-Corporate-Gift-Set.png",
    alt: "Luxury corporate gift set, perfect for showing appreciation to employees, clients, and partners.",
    product_name: "Luxury Gift Set",
    desc: "Impress your business partners or clients with our luxury gift set and add a personal touch with our unlimited customization options.",
  },
];
export const services_corporate = [
  {
    id: 1,
    image: "./Images/Home/Product-images/Trustline-Notepad-Corporate-Gift.png",
    alt: "High-quality notepad, perfect for note taking and organization in the office. Customizable with your company logo.",
    product_name: "Notepad",
    desc: "Order a custom notepad with your company's logo printed on it and explore our wide range of personalization options.",
  },
  {
    id: 1,
    image:
      "./Images/Home/Product-images/Trustline-Custom-USB-Corporate-Gift.png",
    alt: " Custom USB drive, ideal corporate gift personalized with the company's branding.",
    product_name: "USB",
    desc: "USB drives can be made in any color case or clip. Get custom USB sticks with your brand logo on them.",
  },

  {
    id: 1,
    image: "./Images/Home/Product-images/Trustline-Wallet-Corporate-Gift.png",
    alt: "Custom wallet as a corporate gift, made with high-quality materials and featuring our company logo.",
    product_name: "Wallet",
    desc: "Want a gift for a corporate event? Shop from our wide range of custom wallets! Unlimited customization options are available.",
  },
  {
    id: 1,
    image:
      "./Images/Home/Product-images/Trustline-Wireless-Speakers-Bluetooth.png",
    alt: "High-quality wireless Bluetooth speakers, ideal as a corporate gift.",
    product_name: "Wireless Speaker",
    desc: "Get personalized wireless speakers with your brand logo on them. We have a wide selection of portable Bluetooth speakers.",
  },
];
export const services_promotional = [
  {
    id: 1,
    image: "./Images/Home/Product-images/Trustline-Pen-Gift-Box.png",
    alt: "Custom pen gift box as a promotional gift. High-quality pens with the company logo.",
    product_name: "Pen Gift Box ",
    desc: " Can't find the perfect promotional gift? We've got you! Get a custom pen gift box with your company's logo on it. ",
  },
  {
    id: 1,
    image: "./Images/Home/Product-images/Trustline-Tote-Bag.png",
    alt: "Good-quality custom tote bags with branding are ideal for a promotional giveaway.",
    product_name: "Tote Bag",
    desc: "Get custom tote bags with your logo on them. We offer wide range of personalization options you can choose from.",
  },
  {
    id: 1,
    image: "./Images/Home/Product-images/Trustline-Headphones-Custom.png",
    alt: "Custom headphones make a great corporate gift . Personalize with your company's logo for a lasting impression.",
    product_name: "Headphone",
    desc: "Promote your brand in style with custom headphones! Shop our personalized wireless headphones that make great employee gifts.",
  },
  {
    id: 1,
    image: "./Images/Home/Product-images/Trustline-Custom-Backpack-Gift.png",
    alt: "Custom backpack with the company logo, demonstrating the high-quality printing options we provide. ",
    product_name: "Backpack",
    desc: "Backpacks make excellent promotional items. Get a custom backpack with your brand logo on it and promote your business.",
  },
];
export const BestCoorporate = [
  {
    id: 1,
    icon: "./Images/Home/Best-Corporate/Trustline-Free-Consultation-Specialist.svg",
    alt: " Get Free consultation, speak to a specialist and find the ideal corporate gift or uniform..",
    title: "Free Consultation",
    desc: "Speak with one of our Specialists to find the ideal corporate gift or uniform.",
  },
  {
    id: 2,
    icon: "./Images/Home/Best-Corporate/Trustline-Endless-Customization-Options.svg",
    alt: "We provide endless customization options to help make corporate gifts unique.",
    title: "Endless Customization",
    desc: "Our extensive customization choices ensure that all our customers get what they want.",
  },
  {
    id: 3,
    icon: "./Images/Home/Best-Corporate/Trustline-Speedy-Delivery.svg",
    alt: "Custom corporate gifts or uniforms are delivered quickly with our company's fast delivery service.",
    title: "Speedy Delivery",
    desc: "Our team will ensure that your custom gifts or uniforms are delivered at a pre-specified time.",
  },
  {
    id: 4,
    icon: "./Images/Home/Best-Corporate/Trustline-Quality-Customer-Satisfaction.svg",
    alt: "We ensure high-quality products and prioritize client satisfaction for the best corporate gift experience.",
    title: "High-Quality Products",
    desc: "We never compromise on the quality of our products, and the satisfaction of our clients is a top priority.",
  },
  {
    id: 5,
    icon: "./Images/Home/Best-Corporate/Trustline-Brand-Exposure.svg",
    alt: "We offer brand exposure through custom corporate gifts for your events, campaigns, and more.",
    title: "Brand Exposure",
    desc: "We guarantee a maximum brand exposure for any event or campaign.",
  },
];
export const QRCode = [
  {
    h1: "Interested in exploring our",
    h2: "corporate",
    h3: "gifting & uniform solutions? ",
    h5: "Scan the QR code & get in touch with us to get a free quote on all your gifting or uniform needs.",
    qr_code_D: "./Images/Home/Qr-Code/Trustline-WhatsApp-QR-Code-d.svg",
    qr_code_M: "./Images/Home/Qr-Code/Trustline-WhatsApp-QR-Code.svg",
    alt: "Scan our WhatsApp QR code to get in touch with us for all your gifting or uniform needs.",
  },
];
export const ordering_process = [
  {
    id: 1,
    icon: "./Images/Home/ordering-process/Trustline-Consult-Specialists.svg",
    alt: "Consult our specialists to pick the best corporate gifts.",
    title: "Consult",
    text: "We’ll select the best corporate gifts & uniforms based on your needs. ",
  },
  {
    id: 2,
    icon: "./Images/Home/ordering-process/Trustline-Plan-Customizing.svg",
    alt: "Customizing our services to your specific requirements.",
    title: "Plan",
    text: "Share your ideas for customizing our services.",
  },
  {
    id: 3,
    icon: "./Images/Home/ordering-process/Trustline-Confirm-Artwork.svg",
    alt: "We produce a mockup of the product, and once the client confirms, we then proceed with the production.",
    title: "Confirm",
    text: "We'll produce a mockup of the product using your artwork and send it to you for review.",
  },
  {
    id: 4,
    icon: "./Images/Home/ordering-process/Trustline-Order-Gifts.svg",
    alt: "  We build, package, and ship your corporate gift or uniform orders. ",
    title: "Order",
    text: "Place your order and we'll build, package, and ship your gifts and uniforms.",
  },
  {
    id: 5,
    icon: "./Images/Home/ordering-process/Trustline-Deliver-Client-Satisfaction.svg",
    alt: "We prioritize client satisfaction and deliver orders on time.",
    title: "Deliver",
    text: "Receive delivery on time to meet your expectations.",
  },
];
