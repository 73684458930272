import React, { useState, useRef } from "react";
import axios from 'axios';
import "./contactform.css";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Thankyou from "../thankyou/Thankyou";
import Wait from "../../components/loading/Wait";

const ContactForm = () => {
  const form = useRef();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [contactNumber, setContactNumber] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsEmailValid(emailRegex.test(event.target.value));
  };

  const handlePhoneChange = (event) => {
    setContactNumber(event.target.value);
};

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData(e.target);
    const formValues = Object.fromEntries(formData);

    emailjs
    .sendForm(
      "service_oosactm",
      "template_98qzrp9",
      form.current,
      "lTxYrhdzexZWfmP6p"
    )
      .then(() => {
        setIsSubmitted(true);
        navigate("/thank-you");
      })
      .catch((err) => {
        console.error("Failed to send email. Error: ", err);
      })
      .finally(() => {
        setIsLoading(false);
      });

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/contact`, formValues)
      .then(response => {
        console.log('Data successfully sent to server');
      })
      .catch(error => {
        console.error('Failed to send data to server. Error: ', error);
      });
  };

  if (isLoading) {
    return (
      <>
        <Wait />
      </>
    );
  }

  if (isSubmitted) {
    return <Thankyou />;
  }

  return (
    <div className="contact-form p-2 py-4 py-md-5 ">
      <div className="d-flex flex-column contact-title justify-content-center">
        <h3 className=" manrope mx-auto  bold">
          Let’s <text className=" playflair  italic"> talk </text> Gifting
        </h3>
        <p className="medium mx-auto manrope text-center ">
          To get in touch with us, kindly fill the following contact form.
        </p>
      </div>
      <form className="p-lg-3 p-md-4 p-4" ref={form} onSubmit={sendEmail}>
        <div className="py-md-3 py-2">
          <input
            className="no-styling input-padding label-inside border-bottom-1 w-100"
            type="text"
            name="name"
            id="name"
            required
            placeholder=" Name"
          />
        </div>
        <div className="py-md-3 py-2">
          <input
            type=" email"
            name="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder=" Email"
            required
            className={`no-styling input-padding label-inside border-bottom-1 w-100 ${
              !isEmailValid ? "input-error" : ""
            }`}
          />

          {!isEmailValid && (
            <p className="error-message">Please enter a valid email address</p>
          )}
        </div>
        <div className="py-md-3 py-2">
          <div className="d-flex  ">
            <input
              className="no-styling input-padding label-inside border-bottom-1 w-100  "
              type="tel"
              name="contactNumber"
              id="contactNumber"
              value={contactNumber}
              onChange={handlePhoneChange}
              placeholder=" Contact No (e.g.+971 xx xxx xxxx)"
              required
            />
          </div>
        
        </div>
        <div className="py-md-3 py-2">
          <textarea
            className="  input-padding label-inside border-full w-100"
            name="message"
            id="message"
            placeholder=" How can we help you?"
          ></textarea>
        </div>
        <button type="submit" className="send p-2">
          Send
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
