import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
 import Footer from '../../components/footer/Footer'
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import GTM from '../../googletagmanager/GTM';
 import { Helmet } from 'react-helmet';
 import { useMediaQuery } from 'react-responsive';
import DefaultNav1 from '../../components/navbar/defaultnav/DefaultNav1';
import HomeNav from '../../components/navbar/homenav/HomeNav';
import DateShare from './components/DateShare';
import { InstaImage, InstaImageDesktop } from './components/InstaImage';
import Youtube from './components/Youtube';
import './articlepage.css'
import { RelatedArticle, RelatedArticleMob } from './components/RelatedArticle';
import { ArticleHelmet } from '../../PreclickTags/Preclick';

const Article = () => {
  const [redirectTo, setRedirectTo] = useState(null);
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 992 });
  const [post, setPost] = useState(null);
  const { id, } = useParams();   
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/posts/${id}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);  
        console.log("Post data:", data);
        console.log("metaTitle:", data.metaTitle);
        console.log("metaDescription:", data.metaDescription);  
        setPost(data);

      })
      .catch(err => console.error(err));
  }, [id]);   

  const [randoms, setRandoms ] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/random?currentPostId=${id}`);
        console.log(response.data); 
        setRandoms(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchPosts();
  }, [id]);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  const handleClickOpen = () => {
    setRedirectTo("/contact-us ");
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <GTM/>
      <ArticleHelmet  id={id}/>
     
          <Helmet>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.mainParagraph} />
          <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} />
          </Helmet>
          {isDesktopOrLaptop ?   <DefaultNav1 /> : <HomeNav />}
            <div className='d-none d-lg-block section-padding mt-4 '>
            <div className="container container-custom-blogs py-3">
            <div className='d-flex justify-content-center p-lg-2 py-lg-2'>
            <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className='img-fluid    rounded-corners box-shadow-banner'/>
            </div>
            </div>
            </div>
          <div className='d-block d-lg-none mobile-blogs-banner-article'>
          <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className='img-cover-article  '/>
          </div>
          <div className="container container-custom-blogs px-lg-4">
            <div className='d-flex justify-content-center flex-column row '>
            <h1 className='col-md-8 mt-3 mt-lg-4  '>{post.title}</h1>
            <div className="col-12  ">
            <DateShare createdAt={post.createdAt} articleUrl={window.location.href} articleTitle={post.title} />
            </div>
            {post.mainParagraph && post.mainParagraph.split("\n").map((line, lineIndex) => (
                     <React.Fragment key={lineIndex}>
                     <p className=" medium ">{line}</p>
                   </React.Fragment>
                   ))}
              </div>
              </div>
              <div className="container container-custom-blogs">
            <div className='d-flex justify-content-center flex-column row '>
  <div className='d-lg-none block '>
  <InstaImage images={post.images?.map((image, index) => `${process.env.REACT_APP_API_URL}/${image}`)} altforImages={post.altTextSecondary} />
  </div>
  <div className='d-none d-lg-block   px-4'>
  <InstaImageDesktop images={post.images?.map((image, index) => `${process.env.REACT_APP_API_URL}/${image}`)} altforImages={post.altTextSecondary} />
  </div>
</div>
</div>
 
<div className='container container-custom-blogs '>
{post.sections && post.sections.length > 0 && post.sections.map((section, index) => (
  <div key={index} className='w-100 px-lg-2 mt-4'> 
  <h4 className="text-start bold px-lg-2" >{section.subTitle}</h4>
  <p className='px-lg-2'>
    {section.paragraph && section.paragraph.split("\n").map((line, lineIndex) => (
      <React.Fragment key={lineIndex}>
        {line}
        <br />
      </React.Fragment>
    ))}
  </p>
  {section.sectionImage && 
    <div className=' px-lg-2 '>
      <img src={`${process.env.REACT_APP_API_URL}/${section.sectionImage}`} alt={section.subTitle}
        className='w-100 my-3 banner-image object-fit-contain box-shadow-medium' style={{borderRadius:"16px"}}/>
    </div>
  }
  {section.youtubeVideo && 
    <div className='w-100 my-3 my-lg-4'> 
      <Youtube videoLink={section.youtubeVideo} />
    </div>
  }
</div>
))}

</div>

<div className='container container-custom-blogs  px-lg-4 '>
        <DateShare createdAt={post.createdAt} articleUrl={window.location.href} articleTitle={post.title} />
 </div>
                      <button
                      type="button"
                      class="btn secondary-btn-green  btn-lg px-4 mx-auto  "
                      onClick={handleClickOpen}
                    >
                      <h5 className="semibold manrope my-auto"> Contact Us</h5>
                    </button>
 
 
 <div className=" pt-md-5 mt-5 mt-md-0 container container-custom-blogs px-lg-4">

<h3>You might also like </h3>
{randoms.map((random) => (
        <RelatedArticle key={random._id} post={random} />
      ))}

         <div className=' d-block d-md-none  '>
         <Swiper
                      spaceBetween={0}
                      modules={[Pagination]}
                      breakpoints={{
                        0: {
                          slidesPerView: 1.2,
                        },
                        480: {
                          slidesPerView: 1.8,
                        },
                        615: {
                          slidesPerView: 2.1,
                          centeredSlides: false,
                        },
                       992: {
                          slidesPerView: 3,
                          centeredSlides: false,
                        },
                      }}
                    >
 {randoms.map((random) => (
<SwiperSlide className='mb-2'>
<RelatedArticleMob key={random._id} post={random}/>
</SwiperSlide>
 ))}
</Swiper>
 </div>
 </div>
 <div className="section-padding"></div>
 <Footer/>
          </>

  )
}

export default Article
