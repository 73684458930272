import React from "react";

import { useState, useEffect } from "react";
import {
  RiFacebookBoxLine,
  RiFacebookBoxFill,
  RiWhatsappLine,
  RiWhatsappFill,
  RiInstagramLine,
  RiInstagramFill,
  RiLinkedinBoxLine,
  RiLinkedinBoxFill,
  RiTiktokFill
} from "react-icons/ri";
 
export const Facebook = () => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const facebook = document.getElementById("facebook");
    facebook.addEventListener("click", () => {
      window.open("      https://www.facebook.com/Trustlinegifts/", "_blank");
    });
  }, []);

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        id="facebook"
        rel="noopener noreferrer"
      >
        {isHovered ? <RiFacebookBoxFill /> : <RiFacebookBoxLine />}
      </div>
    </>
  );
};
export const Whatsapp = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClick = () => {
    window.open("https://wa.me/message/IYDJ3WNMV74BF1", "_blank");
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        onClick={handleClick}
        rel="noopener noreferrer"
      >
        {isHovered ? <RiWhatsappFill /> : <RiWhatsappLine />}
      </div>
    </>
  );
};

export const Instagram = () => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const instagram = document.getElementById("instagram");
    instagram.addEventListener("click", () => {
      window.open("      https://www.instagram.com/trustlinegifts/", "_blank");
    });
  }, []);

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        id="instagram"
      >
        {isHovered ? <RiInstagramFill /> : <RiInstagramLine />}
      </div>
    </>
  );
};
export const LinkedIn = () => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const linkedin = document.getElementById("linkedin");
    linkedin.addEventListener("click", () => {
      window.open(
        "      https://www.linkedin.com/company/trustline-general-trading-llc/",
        "_blank"
      );
    });
  }, []);

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        id="linkedin"
      >
        {isHovered ? <RiLinkedinBoxFill /> : <RiLinkedinBoxLine />}
      </div>
    </>
  );
};
export const TikTok = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClickfacebook = () => {
    window.open("   https://www.tiktok.com/@trustlinegifts?lang=en", "_blank");
  };

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        onClick={handleClickfacebook}
        id="facebook"
        
      >
        {isHovered ? < RiTiktokFill /> : < RiTiktokFill/>}
      </div>
    </>
  );
};