import React from 'react'
import img from '../../Images/blogs/banner-exploring-expert-blogs-corporate-gifts-uniforms-illustration.jpg'
import imgMob from '../../Images/blogs/exploring-expert-blogs-corporate-gifts-uniforms-illustration.jpg'
import Footer from '../../components/footer/Footer'
import { useMediaQuery } from 'react-responsive';
  import './bloghome.css'
import { bloghome } from '../../data/blogs'
import DefaultNav1 from '../../components/navbar/defaultnav/DefaultNav1'
import HomeNav from '../../components/navbar/homenav/HomeNav';
import RecentBlogs from './RecentBlogs';
import GTM from '../../googletagmanager/GTM';
import { BlogHelmet } from '../../PreclickTags/Preclick';
const BlogHome = () => {
  const screenWidth = window.innerWidth;
 // Define breakpoint for  width
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 992 });
return (
 <>
   <GTM />
   <BlogHelmet/>
   {isDesktopOrLaptop ?   <DefaultNav1 /> : <HomeNav />}

 <div className='bg-dasOffWhite d-flex flex-column min-vh-100'>
  <div className='flex-grow-1'>
 
    <div>
      {bloghome.map((val) => (
        <>
          <div className='d-none d-lg-block  section-padding mt-4  '>
            <div className="container">
            <div className='d-flex justify-content-center  '>
            <img src={img} alt={val.alt} className='img-fluid shadow rounded-corners'/>
            </div>
            </div>
          </div>

          <div className='d-block d-lg-none mobile-blogs-banner'>
          <img src={imgMob} alt={val.alt2} className='img-cover  '/>
          </div>

        </>
      ))}
    </div>
<RecentBlogs/>
  </div>
  <Footer/>
</div>

 </>
  )
}

export default BlogHome
