import React, { useEffect } from "react";

const ServicesLinks = () => {
  useEffect(() => {
    const button1 = document.getElementById("button1");
    const button2 = document.getElementById("button2");
    const button3 = document.getElementById("button3");
    const button4 = document.getElementById("button4");
    const button5 = document.getElementById("button5");
    button1.addEventListener("click", (e) => {
      e.preventDefault();
      window.location.href = "/#personalised-gift";
    });

    button2.addEventListener("click", (e) => {
      e.preventDefault();
      window.location.href = "/#curated-gift-sets";
    });

    button3.addEventListener("click", (e) => {
      e.preventDefault();
      window.location.href = "/#corporate-event-gift";
    });

    button4.addEventListener("click", (e) => {
      e.preventDefault();
      window.location.href = "/#promotional-gifts";
    });
    button5.addEventListener("click", (e) => {
      e.preventDefault();
      window.location.href = "/";
      setTimeout(() => {
        const div4 = document.getElementById("div4");
        div4.scrollIntoView({ behavior: "smooth" });
      }, 0);
    });
  }, []);
  return (
    <>
      {" "}
      <div class=" footerservices d-flex flex-column">
        <button id="button1">
          <p className="regular d-flex justify-content-start ">
            Personalized Gifts
          </p>
        </button>
        <button id="button2">
          <p className="regular  d-flex justify-content-start ">
            Curated Gift Sets
          </p>
        </button>
        <button id="button3">
          <p className="regular   d-flex justify-content-start">
            Corporate Events
          </p>
        </button>
        <button id="button4">
          <p className="regular  d-flex justify-content-start ">
            Promotional Gifts
          </p>
        </button>
      </div>
    </>
  );
};

export default ServicesLinks;
