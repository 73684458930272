import React, { useState, useEffect } from 'react';
  import { truncateText, useScreenSize, getTitleCharLimit, getParaCharLimit } from '../../utils/truncateText';
import { Link } from 'react-router-dom';
import { DateCreated } from '../article/components/DateShare';
import slugify from 'slugify';

const RecentBlogs = () => {
  const [posts, setPosts] = useState([]);
  const { isLargeScreen, isMediumScreen, isSmallScreen } = useScreenSize();
  const titleCharLimit = getTitleCharLimit(isLargeScreen, isMediumScreen, isSmallScreen);
  const paraCharLimit = getParaCharLimit(isLargeScreen, isMediumScreen, isSmallScreen);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/posts`)
      .then(response => response.json())
      .then(data => {
        const sortedPosts = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPosts(sortedPosts);
      })
   }, []);

  return (
    <>
    <div className="container mx-auto container-custom-blogs section-padding">
      <h1 className='mb-4'>Articles</h1>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
        {posts.map((post) => (
          <div className='mb-5 px-lg-3' key={post._id}>
            <Link to={`/blogs/article/${slugify(post.title, { lower: true })}/${post._id}`} style={{ textDecoration:"none" }}>  
            <div className='card box-shadow-medium rounded-corners d-flex flex-column  '>  {/* Add d-flex flex-column here */}
  <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className='card-img-top rounded-image-top ' />
  <div className='card-body d-flex flex-column'>  {/* Add d-flex flex-column here if you want the content to take up the full space of the body */}
    <h5 className='card-title'>{truncateText(post.title, titleCharLimit)}</h5>
    <p className='card-text text-muted'>{truncateText(post.mainParagraph, paraCharLimit)}</p> 
    <div className='font-weight-bold mt-auto'> {/* Add mt-auto here */}
      <p className='text-muted'><DateCreated createdAt={post.createdAt}/></p>
    </div>
  </div>
</div>
            </Link>
          </div>
        ))}
      </div>
      </div>
    </>
  )
}


export default RecentBlogs
