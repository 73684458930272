import React from "react";
import { empty } from "../../data/empty";
import "./emptystates.css";
import { useState, useEffect } from "react";
const NotFound = () => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  function handleClickHome() {
    setRedirectTo("/");
  }
  function handleClickContact() {
    setRedirectTo("/contact-us");
  }
  return (
    <>
      <section className="not-found  " style={{ background: "#fdfffc" }}>
        <div className="container section-padding">
          <div className="row text-center">
            {empty.map((val) => (
              <>
                <h1 className="semibold manrope">{val.head}</h1>
                <h5 className="manrope semibold">{val.desc}</h5>
                <div className="not-found-image py-lg-5 py-3">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/empty-states/Trustline-Page-Not-Found.svg"
                    }
                    alt={val.alt}
                  />
                </div>

                <div class="d-grid gap-2 d-sm-flex empty-states-buttons justify-content-sm-center">
                  <button
                    type="button"
                    class="go-back-home py-lg-3 py-2 btn-lg px-4  "
                    onClick={handleClickHome}
                  >
                    <span className="semibold manrope my-auto">
                      Go Back Home
                    </span>
                  </button>
                  <button
                    type="button"
                    class="  not-found-contact py-lg-3 py-2 btn-lg px-4"
                    onClick={handleClickContact}
                  >
                    <span className="semibold manrope my-auto">Contact Us</span>
                  </button>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
