import React from "react";
import { QRCode } from "../../../data/home";
export const QrCode = () => {
  return (
    <>
      <section className="qr-code">
        <div className="container">
          {QRCode.map((val) => (
            <>
              <div className="row py-4 d-flex justify-content-center align-items-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 my-auto ">
                  <div className="title   ">
                    <h2 className="semibold mymanrope">
                      Interested in exploring our{" "}
                      <text className=" pe-2 playflair  fst-italic">
                        corporate gifting & uniform
                      </text>
                      solutions?{" "}
                    </h2>
                    <h5 className="medium manrope py-3">{val.h5}</h5>
                  </div>
                </div>
                <div className="col-12 hidemeformobile col-sm-12 col-md-12 col-lg-6">
                  <div className="qr-image">
                    <img src={val.qr_code_D} alt={val.alt} />
                  </div>
                </div>
              </div>
              <div className="col-12 mx-auto showmeformobile">
                <div className="    d-flex justify-content-center ">
                  <img src={val.qr_code_M} alt={val.alt} />
                </div>
              </div>
            </>
          ))}
        </div>
      </section>
    </>
  );
};
