import React, { useState, useEffect } from "react";
import { cookie_consent } from "../data/cookie";
import "./cookie.css";
import { GrClose } from "react-icons/gr";
import img from '../Images/cookie/Trustline-Cookies-Experience.svg'
const CookieConsent = () => {
  const [accepted, setAccepted] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const acceptedCookies = getCookie("acceptedCookies");
    if (acceptedCookies) {
      setAccepted(true);
    } else {
      setTimeout(() => {
        setShowBanner(true);
      }, 12000);
    }
  }, []);

  function setCookie(name, value, days) {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(
      value
    )}; expires=${expires}`;
  }

  function getCookie(name) {
    return document.cookie.split("; ").reduce((r, v) => {
      const parts = v.split("=");
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, "");
  }

  return (
    <>
      {showBanner && !accepted && (
        <div className="      row ">
          <div className="col-lg-8 col-xl-6 col-xxl-4   col-md-9  col-sm-10 col-12  d-flex flex-column cookie-banner pb-2 pb-sm-1       animated-banner py-xxl-4 py-lg-3   ">
            <div className="row d-flex flex-row flex-wrap   ">
              <div className="   col-lg-2 col-xl-3 col-xxl-3     col-3  cookie-image   mx-auto  my-auto col-sm-3">
                {cookie_consent.map((val) => (
                  <>
                    <img src={img} alt={val.alt} />
                  </>
                ))}
              </div>
              <div className="content col-lg-9 col-md-9 col-xxl-8 col-xl-8 my-auto pt-2 pt-md-2 pt-xl-2 pt-xxl-2  mx-auto col-9    col-sm-9 ">
                <div className="row d-flex ">
                  <div className="col-10 col-md-10">
                    <h5 className="bold manrope head ">Hi there,</h5>
                  </div>
                  <div className="col-2 col-md-2">
                    <button
                      className="close-button mx-auto  col-8   col-md-6  "
                      onClick={() => setShowBanner(false)}
                    >
                      <i>
                        <GrClose />
                      </i>
                    </button>
                  </div>
                </div>

                <p>
                  We utilize cookies to enhance your browsing experience and
                  analyze site traffic. You can opt to close this and proceed
                  with essential cookies only. Further information can be found
                  in our
                  <a href="/cookie-policy" className="px-1">
                    Cookie Policy.
                  </a>
                </p>

                <div className="row     w-100 mx-auto">
                  <button
                    onClick={() => {
                      setAccepted(true);
                      setCookie("acceptedCookies", true, 365);
                      setShowBanner(false);
                    }}
                    className="p-1 accept-btn"
                  >
                    Accept All
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
