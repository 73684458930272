import React from "react";
import { footer_reachus } from "../../../data/footer";
import "../location/location.css";

export const Contact = () => {
  return (
    <div>
      {footer_reachus.map((val) => (
        <>
          <a href="tel:+9714-397-8705" className="text-decoration-none">
            <div role="button" className="location d-flex gap-3 flex-row">
              <h5 className=" align-self-start d-flex">{val.icon_phone}</h5>
              <p className="regular   ">{val.contact}</p>
            </div>
          </a>
        </>
      ))}
    </div>
  );
};

export const Mob = () => {
  return (
    <div>
      {footer_reachus.map((val) => (
        <>
          <a href="tel:+971-52-631-0235" className="text-decoration-none">
            <div role="button" className="location d-flex gap-3 flex-row">
              <h5 className=" align-self-start d-flex">{val.icon_phone2}</h5>
              <p className="regular ">{val.mob}</p>
            </div>
          </a>
        </>
      ))}
    </div>
  );
};
