import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import axios from 'axios';
import slugify from "slugify";
export const HomeHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Best Corporate Gifting & Uniform Services | Trustline"
    : "Best Corporate Gifting & Uniform Services | Trustline";
  const description = isMobile
    ? "Pens - Bags - T-shirts - Hoodies - Caps - Bottles - Keychains and more. Call today & get your custom products.	"
    : "Pens - Bags - Wallets - Uniforms - Hoodies - Power bank - Caps - Bottles - Keychains - Wireless Speakers - Notepads - Promotional Gifts - Custom Gift Sets.";

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinegifts.com",
    logo: "https://www.trustlinegifts.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinegifts.com./Images/Home/whatsnew/trustline-tshirt.png",
    telephone: "+9714 397 8705, +971 52 631 0235",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      " https://www.facebook.com/Trustlinegifts/",
      "   https://www.instagram.com/trustlinegifts/",
      "  https://www.linkedin.com/company/trustline-general-trading-llc/",
    ],
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const AboutHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "About Us | Corporate Gifts & Uniforms | Trustline"
    : "About Us | Corporate Gifts & Uniforms | Trustline";
  const description = isMobile
    ? "Leading provider of Corporate Gifts & Uniforms in Dubai, UAE. We offer a wide range of options to suit your needs."
    : "Trustline is a leading provider of Corporate Gifts & Uniforms in Dubai, UAE. We specialize in custom gift sets, gift packaging and personalized uniforms.";

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinegifts.com/about-us",
    logo: "https://www.trustlinegifts.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinegifts.com/Images/about/Trustline-Dubai-Gift-Supplier.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      " https://www.facebook.com/Trustlinegifts/",
      "   https://www.instagram.com/trustlinegifts/",
      "  https://www.linkedin.com/company/trustline-general-trading-llc/",
    ],
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const ContactHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Contact Us | Corporate Gifts & Uniforms | Trustline"
    : "Contact Us | Corporate Gifts & Uniforms | Trustline";
  const description = isMobile
    ? "   Contact us at 04-397-8705 | 052-631-0235 & let our specialists assist you in picking the perfect product or uniform."
    : " Contact us at 04-397-8705 | 052-631-0235 & let our corporate gift specialists pick the best gift or uniform for your employees, clients or partners.";
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinegifts.com/contact-us",
    logo: "https://www.trustlinegifts.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinegifts.com/Images/Home/Product-images/Trustline-Welcome-Gift-Set.png",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      " https://www.facebook.com/Trustlinegifts/",
      "   https://www.instagram.com/trustlinegifts/",
      "  https://www.linkedin.com/company/trustline-general-trading-llc/",
    ],
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};

export const ThankyouHelmet = () => {
  const title = "Your Satisfaction is Our Goal | Trustline";
  const description =
    "Thank you for choosing us, your satisfaction is our priority. Call us today & get the perfect corporate gift or uniform!";

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinegifts.com/thank-you",
    logo: "https://www.trustlinegifts.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinegifts.com/Images/thankyou/Trustline-Thank-You-after.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      " https://www.facebook.com/Trustlinegifts/",
      "   https://www.instagram.com/trustlinegifts/",
      "  https://www.linkedin.com/company/trustline-general-trading-llc/",
    ],
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};

export const CookieHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Cookies Policy | Trustline"
    : "Cookies Policy | Trustline";
  const description = isMobile
    ? "By using our website, you acknowledge and consent to our use of cookies as outlined in this Cookie Policy."
    : "We use cookies on website to improve your browsing experience and to understand how you use our website. We are committed to protecting your personal data.";
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinegifts.com/cookie-policy",
    logo: "https://www.trustlinegifts.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    image:
      "https://www.trustlinegifts.com/Images/cookie/Trustline-Cookies-Experience.svg",
    telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      " https://www.facebook.com/Trustlinegifts/",
      "   https://www.instagram.com/trustlinegifts/",
      "  https://www.linkedin.com/company/trustline-general-trading-llc/",
    ],
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const PrivacyHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Privacy Policy | Trustline"
    : "Privacy Policy | Trustline";
  const description = isMobile
    ? "We ensure that your personal information is protected in accordance with the laws of the UAE."
    : " We only request personal information when it is necessary to provide a service to you. We obtain it through fair and legal means, with your consent.";
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinegifts.com/privacy-policy",
    logo: "https://www.trustlinegifts.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};

export const TandCHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinegifts.com/terms-and-conditions",
  };
  const title = isMobile
    ? "Terms & Conditions | Trustline"
    : "Terms & Conditions | Trustline";
  const description = isMobile
    ? "By using our website, you agree to be bound by these Terms. If you have any questions, please contact us!"
    : "By using our website you agree to be bound by these Terms. These Terms shall be governed by and construed in accordance with the laws of the UAE.";
  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const SitemapHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinegifts.com/sitemap",
  };
  const title = isMobile
    ? "Site Map | Best in Corporate gifts & Uniforms | Trustline"
    : "Site Map | Best in Corporate gifts & Uniforms | Trustline";
  const description = isMobile
    ? "Trustline's Corporate Gifting & Uniform Site Map - Explore our wide range of custom & curated gift sets."
    : "Trustline's corporate gifting & uniform site map - explore our wide range of custom gift sets, curated gift sets, custom uniforms and promotional gifts.";
  return (
    <Helmet>
      <title>{title}</title>
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const NotFoundHelmet = () => {
  return (
    <Helmet>
      <title>Error 404 Page Not Found</title>
      <meta
        data-react-helmet="true"
        name="description"
        content="Oops! The page you are looking for cannot be found. Please check the URL or contact us for help."
      />{" "}
    </Helmet>
  );
};
export const BlogHelmet = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  const title = isMobile
    ? "Blogs | Trustline Gifts "
    : "Blogs | Trustline Gifts";
  const description = isMobile
    ? "Explore our diverse blogs on corporate gifts and uniforms in Dubai. Stay updated on the latest trends and ideas."
    : "Explore our latest blogs on corporate gifts and uniforms. Get insights, ideas, and inspiration for your Dubai business. Stay ahead with Trustline Gifts.";

 const url =  "https://www.trustlinegifts.com/blogs" 
const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    url: "https://www.trustlinegifts.com/blogs",
    logo: "https://www.trustlinegifts.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    
     telephone: "+9714 397 8705, +971 58 1920960",
    priceRange: "Free Consultation",
    openingHours: "Mo-Fri 09:00-18:00 ",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 404, Nastaran Tower",
      addressLocality: "Jadaf Waterfront, Al Jaddaf",
      addressRegion: "Dubai",
      addressCountry: "UAE",
    },
    sameAs: [
      " https://www.facebook.com/Trustlinegifts/",
      "   https://www.instagram.com/trustlinegifts/",
      "  https://www.linkedin.com/company/trustline-general-trading-llc/",
    ],
  };

  return (
    <Helmet>
     <title>{title}</title>
     <link rel="canonical" href={url} />  
      <meta data-react-helmet="true" name="description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  );
};
export const  ArticleHelmet = ({ id }) => {
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      const fetchPost = async () => {
        try {
          setIsLoading(true);
          const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
          setPost(data);
          setIsLoading(false);
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      };
      fetchPost();
  }, [id]);
  
  if (isLoading || !post) {
    return <div>Loading...</div>;
  }

  const title = slugify(post.title, { lower: true });
  const description = post.metaDescription;
  const keywords = post.metaKeywords;
  const url = `https://www.trustlinegifts.com/blogs/article/${title}/${id}`;   

const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    url,
    headline: title,
    description,
    datePublished: post.createdAt,
    dateModified: post.updatedAt || post.createdAt,
 
    publisher: {
      "@type": "Organization",
      name: "Trustline",  
      logo: "https://www.trustlinegifts.com/Images/logo/green/Navbar_Green_Logo_Default.svg",
    },
    mainEntityOfPage: url,
    image: `https://www.trustlinegifts.com/${post.mainBanner}`,  
  };

  return (
<Helmet>
<title>{post.metaTitle}</title>
     <link rel="canonical" href={url} />  
  <meta name="description" data-react-helmet="true" content={post.metaDescription} />
  <meta name="keywords" content={post.metaKeywords} />
  <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
</Helmet>

  );
};